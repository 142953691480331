import React from 'react';

const Home = (props) => {
  return (
    <div>
      <h1>Loop<sup>11</sup>'s Design System</h1>
    </div>
  );
}

export default Home;
