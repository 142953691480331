import React from 'react';

const loadingMsg = [
  "Sixty percent of the time it works every time.",
  "What’s coming is kind of a big deal.",
  "This wait has placed me in a glass cage of emotion!",
  "By the beard of Zeus!",
  "I love lamp.",
  "Well... THAT escalated quickly.",
  "This is embarrassing....I'm totally unprepared...",
  "I’m sorry, I don’t speak Spanish"
]

const LoadingMessage = (props) => {

  const getMsg = () => {
    return loadingMsg[Math.floor(Math.random()*loadingMsg.length)];
  }

  const msg = getMsg();

  return (
    <div id="loadingContainer">
      <h1>Loading...</h1>
      <h3>{msg}</h3>
    </div>
  );
}

export default LoadingMessage;
