import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import logo from './logo.svg';

const Nav = props => {

  useEffect( () => {

    // Scroll top top of page when nav clicked
    const content = document.getElementById('id-main-content');
    // guard for testing
    if (typeof content !== 'undefined' && content !== null){
      content.scrollTo(0, 0);
    }

  });


  return(
    <nav className="main-nav">
      <a href="/" className="Loop11-logo"><img src={logo} alt="Loop11" /></a>
      <ul>
        <li>
          <NavLink to='/style/' className="nav-category">Styles</NavLink>
          <ul>
            <li><NavLink to='/style/color/' className="nav-child">Color</NavLink></li>
            <li><NavLink to='/style/grid/' className="nav-child">Grid</NavLink></li>
            <li><NavLink to='/style/layer/' className="nav-child">Layer</NavLink></li>
            <li><NavLink to='/style/spacing/' className="nav-child">Spacing</NavLink></li>
            <li><NavLink to='/style/typography/' className="nav-child">Typography</NavLink></li>
          </ul>
        </li>
        <li><NavLink to='/components/' className="nav-category">Components</NavLink>
          <ul>
            <li><NavLink to='/components/alert/' className="nav-child">Alert</NavLink></li>
            <li><NavLink to='/components/form/' className="nav-child">Form</NavLink>
              <ul>
                <li><NavLink to='/components/buttons/' className="nav-child">Buttons</NavLink></li>
                <li><NavLink to='/components/imagebuttons/' className="nav-child">Image Buttons</NavLink></li>
                <li><NavLink to='/components/checkbox/' className="nav-child">Checkbox</NavLink></li>
                <li><NavLink to='/components/dropdown/' className="nav-child">Dropdown</NavLink></li>
                <li><NavLink to='/components/radio/' className="nav-child">Radio</NavLink></li>
                <li><NavLink to='/components/toggle/' className="nav-child">Toggle</NavLink></li>
                <li><NavLink to='/components/text-fields/' className="nav-child">Text Fields</NavLink></li>
                <li><NavLink to='/components/segment' className="nav-child">Segment Filters</NavLink></li>
              </ul>
            </li>
            <li><NavLink to='/components/overflow/' className="nav-child">Overflow</NavLink></li>
            <li><NavLink to='/components/tag/' className="nav-child">Tag</NavLink></li>
            <li><NavLink to='/components/tooltip/' className="nav-child">Tooltip</NavLink></li>
            <li><NavLink to='/components/form/' className="nav-child">Icons & images</NavLink>
              <ul>
                <li><NavLink to='/components/user-icon/' className="nav-child">User Icons</NavLink></li>
                <li><NavLink to='/components/list-icon/' className="nav-child">List Icons</NavLink></li>
              </ul>
            </li>
            <li><NavLink to='/components/chart-pie/' className="nav-child">Charts</NavLink>
              <ul>
                <li><NavLink to='/components/chart-pie/' className="nav-child">Pie</NavLink></li>
                <li><NavLink to='/components/chart-gauge/' className="nav-child">Gauge</NavLink></li>
                <li><NavLink to='/components/chart-bar/' className="nav-child">Bar</NavLink></li>
                <li><NavLink to='/components/chart-clickstream/' className="nav-child">Clickstream</NavLink></li>
              </ul>
            </li>
            <li><NavLink to='/components/list-main-dashboard/' className="nav-child">Lists</NavLink>
              <ul>
                <li><NavLink to='/components/list-data-table/' className="nav-child">Data table</NavLink></li>
                <li><NavLink to='/components/list-simple/' className="nav-child">Simple List</NavLink></li>
                <li><NavLink to='/components/list-main-dashboard/' className="nav-child">Main Dashboard</NavLink></li>
                <li><NavLink to='/components/list-video-participants/' className="nav-child">Video Participants</NavLink></li>
                <li><NavLink to='/components/list-specific-participant/' className="nav-child">Specific Participant</NavLink></li>
                <li><NavLink to='/components/list-click-path/' className="nav-child">Click Path</NavLink></li>
                <li><NavLink to='/components/list-tasks-questions/' className="nav-child">Tasks / Questions</NavLink></li>
              </ul>
            </li>
            <li><NavLink to='/components/navigation-primary/' className="nav-child">Navigation</NavLink>
              <ul>
                <li><NavLink to='/components/navigation-primary/' className="nav-child">Primary</NavLink></li>
                <li><NavLink to='/components/navigation-secondary/' className="nav-child">Secondary</NavLink></li>
              </ul>
            </li>
            <li><NavLink to='/components/page-constants/' className="nav-child">Page Constants</NavLink>
              <ul>
                <li><NavLink to='/components/context-header/' className="nav-child">Context Headers</NavLink></li>
                <li><NavLink to='/components/report-summary/' className="nav-child">Report Summary</NavLink></li>
              </ul>
            </li>
          </ul>
        </li>
        <li><NavLink to='/components/page/study-creation/' className="nav-category">Pages</NavLink>
          <ul>
            <li><NavLink to='/components/page/study-creation' className="nav-child">Study Creation</NavLink></li>
          </ul>
        </li>
        <li><NavLink to='/reports/project-details/' className="nav-category">Project Details</NavLink></li>
      </ul>
    </nav>
  );
}

export default Nav;
