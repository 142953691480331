export const questionRows = [
    {id: 0, questionId: 123456780, row: "Very Easy", placeholder: "", order: 1},
    {id: 1, questionId: 123456780, row: "Easy", placeholder: "", order: 2},
    {id: 2, questionId: 123456780, row: "Neither easy nor difficult", placeholder: "", order: 3},
    {id: 3, questionId: 123456780, row: "Difficult", placeholder: "", order: 4},
    {id: 4, questionId: 123456780, row: "Very difficult", placeholder: "", order: 5},

    {id: 0, questionId: 123456781, row: "Professional", placeholder: "", order: 1},
    {id: 1, questionId: 123456781, row: "Cluttered", placeholder: "", order: 2},
    {id: 2, questionId: 123456781, row: "Easy to use", placeholder: "", order: 3},
    {id: 3, questionId: 123456781, row: "Confusing", placeholder: "", order: 4},

    {id: 0, questionId: 123456782, row: "I think that I would like to use this frequently", placeholder: "", order: 1},
    {id: 1, questionId: 123456782, row: "I found this unnecessarily complex", placeholder: "", order: 2},
    {id: 2, questionId: 123456782, row: "I thought this was easy to use", placeholder: "", order: 3},
    {id: 3, questionId: 123456782, row: "I think that I would need the support of a technical person to be able to use this", placeholder: "", order: 4},
    {id: 4, questionId: 123456782, row: "I found the various functions were well integrated", placeholder: "", order: 5},
    {id: 5, questionId: 123456782, row: "I thought there was too much inconsistency", placeholder: "", order: 6},
    {id: 6, questionId: 123456782, row: "I would imagine that most people would learn to use this very quickly", placeholder: "", order: 7},
    {id: 7, questionId: 123456782, row: "I found this very cumbersome to use", placeholder: "", order: 8},
    {id: 8, questionId: 123456782, row: "I felt very confident using this", placeholder: "", order: 9},
    {id: 9, questionId: 123456782, row: "I needed to learn a lot of things before I could get going with this", placeholder: "", order: 10},
    
    {id: 0, questionId: 123456783, row: "I think that I would like to use this frequently", placeholder: "", order: 1},
    {id: 1, questionId: 123456783, row: "I found this unnecessarily complex", placeholder: "", order: 2},
    {id: 2, questionId: 123456783, row: "I thought this was easy to use", placeholder: "", order: 3},
    {id: 3, questionId: 123456783, row: "I think that I would need the support of a technical person to be able to use this", placeholder: "", order: 4},

    {id: 0, questionId: 123456784, row: "Poor", placeholder: "", order: 1},
    {id: 1, questionId: 123456784, row: "Fair", placeholder: "", order: 2},
    {id: 2, questionId: 123456784, row: "Good", placeholder: "", order: 3},
    {id: 3, questionId: 123456784, row: "Excellent", placeholder: "", order: 4},

    {id: 0, questionId: 123456785, row: "Site map", placeholder: "", order: 1},
    {id: 1, questionId: 123456785, row: "Search function steeple beeple woop rounders", placeholder: "", order: 2},
    {id: 2, questionId: 123456785, row: "Video", placeholder: "", order: 3},
    {id: 3, questionId: 123456785, row: "RSS feeds", placeholder: "", order: 4},

    {id: 0, questionId: 123456787, row: "First", placeholder: "", order: 1},
    {id: 1, questionId: 123456787, row: "Second", placeholder: "", order: 2},
    {id: 2, questionId: 123456787, row: "Third", placeholder: "", order: 3}
]

export default questionRows;
