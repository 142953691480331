
const user = {
    id: 1000,
    email: "carlos.jefferson@gmail.com",
    firstName: "Carlos",
    lastName: "Jefferson",
    plan: "Some Plan",
    team: true
}

export default user;