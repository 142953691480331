const theme = [
      {
        "id": 1,
        "name": "Default Loop11 Theme",
        "uuid": "12345678901",
        "logoFilename": "",
        "displayLogo":false,
        "colourMainBackground": "#d0cef7",
        "colourMainForeground": "#55538b",
        "colourSecondaryBackground": "#f4d3ef",
        "colourSecondaryForeground": "#90408f",
        "colourButtonBackground": "#f4f6cf",
        "colourButtonForeground": "#8a8e43",
        "colourMinimizedBackground": "#f1d9ce",
        "colourMinimizedForeground": "#7d4932"
      },
      {
        "id": 2,
        "name": "The new blue",
        "uuid": "12345678902",
        "logoFilename": "",
        "displayLogo":false,
        "colourMainBackground": "#80cef7",
        "colourMainForeground": "#g5538b",
        "colourSecondaryBackground": "#34d3ef",
        "colourSecondaryForeground": "#n0408f",
        "colourButtonBackground": "#74f6cf",
        "colourButtonForeground": "#sa8e43",
        "colourMinimizedBackground": "#11d9ce",
        "colourMinimizedForeground": "#id4932"
      },
      {
        "id": 3,
        "name": "Brand old",
        "uuid": "12345678903",
        "logoFilename": "/images/test-theme-logo.jpg",
        "displayLogo":true,
        "colourMainBackground": "#d0cDf7",
        "colourMainForeground": "#55P38b",
        "colourSecondaryBackground": "#f453ef",
        "colourSecondaryForeground": "#90C08f",
        "colourButtonBackground": "#f436cf",
        "colourButtonForeground": "#828e23",
        "colourMinimizedBackground": "#f1D9ce",
        "colourMinimizedForeground": "#794932"
      }
    ]

export default theme;
