const moderatedParticipant = [
      {
        "uuid": "46b77c25-ad3f-4da9-98de-0de94fbbad20",
        "created": "2019-03-12 13:53:18.523421+00",
        "modified":"2019-03-12 13:53:18.523449+00",
        "first_name": "Bill",
        "participant_id": 123456,
        "description": "Meeting at 10am on Wednesday",
        "moderator_id": "007c8f79-ab60-4d5a-a08a-1ce6be448bcd",
        "recording_session_token": null,
        "deleted":false
      },
      {
        "uuid": "46b77c25-ad3f-4da9-98de-0de94fbbad19",
        "created": "2019-03-12 13:53:18.523421+00",
        "modified":"2019-03-12 13:53:18.523449+00",
        "first_name": "Bumbly dumble",
        "participant_id": 123457,
        "description": "Meeting at 11am on Thursday",
        "moderator_id": "007c8f79-ab60-4d5a-a08a-1ce6be448bcd",
        "recording_session_token": null,
        "deleted":false
      },
      {
        "uuid": "46b77c25-ad3f-4da9-98de-0de94fbbad19",
        "created": "2019-03-12 13:53:18.523421+00",
        "modified":"2019-03-12 13:53:18.523449+00",
        "first_name": "Deleted user name",
        "participant_id": 123457,
        "description": "This should not be showing if filtering works",
        "moderator_id": "007c8f79-ab60-4d5a-a08a-1ce6be448bcd",
        "recording_session_token": null,
        "deleted":true
      },
      {
        "uuid": "46b77c25-ad3f-4da9-98de-0de94fbbad18",
        "created": "2019-03-12 13:53:18.523421+00",
        "modified":"2019-03-12 13:53:18.523449+00",
        "first_name": "Soap box",
        "participant_id": 123458,
        "description": "Meeting at 9am on Friday",
        "moderator_id": "007c8f79-ab60-4d5a-a08a-1ce6be448bcd",
        "recording_session_token": null,
        "deleted":false
      },
      {
        "uuid": "46b77c25-ad3f-4da9-98de-0de94fbbad17",
        "created": "2019-03-12 13:53:18.523421+00",
        "modified":"2019-03-12 13:53:18.523449+00",
        "first_name": "Participant 4",
        "participant_id": null,
        "description": "",
        "moderator_id": "007c8f79-ab60-4d5a-a08a-1ce6be448bcd",
        "recording_session_token": null,
        "deleted":false
      },
      {
        "uuid": "46b77c25-ad3f-4da9-98de-0de94fbbad16",
        "created": "2019-03-12 13:53:18.523421+00",
        "modified":"2019-03-12 13:53:18.523449+00",
        "first_name": "Participant 5",
        "participant_id": null,
        "description": "",
        "moderator_id": "007c8f79-ab60-4d5a-a08a-1ce6be448bcd",
        "recording_session_token": null,
        "deleted":false
      },
      {
        "uuid": "46b77c25-ad3f-4da9-98de-0de94fbbad15",
        "created": "2019-03-12 13:53:18.523421+00",
        "modified":"2019-03-12 13:53:18.523449+00",
        "first_name": "Participant 6",
        "participant_id": null,
        "description": "",
        "moderator_id": "007c8f79-ab60-4d5a-a08a-1ce6be448bcd",
        "recording_session_token": null,
        "deleted":false
      },
      {
        "uuid": "46b77c25-ad3f-4da9-98de-0de94fbbad14",
        "created": "2019-03-12 13:53:18.523421+00",
        "modified":"2019-03-12 13:53:18.523449+00",
        "first_name": "Participant 7",
        "participant_id": null,
        "description": "",
        "moderator_id": "007c8f79-ab60-4d5a-a08a-1ce6be448bcd",
        "recording_session_token": null,
        "deleted":false
      },
      {
        "uuid": "46b77c25-ad3f-4da9-98de-0de94fbbad13",
        "created": "2019-03-12 13:53:18.523421+00",
        "modified":"2019-03-12 13:53:18.523449+00",
        "first_name": "Participant 8",
        "participant_id": null,
        "description": "",
        "moderator_id": "007c8f79-ab60-4d5a-a08a-1ce6be448bcd",
        "recording_session_token": null,
        "deleted":false
      }
    ]

export default moderatedParticipant;
