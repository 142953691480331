import React, { Component, lazy, Suspense } from 'react';
import '../scss/application.scss';
import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom';

// Components
import Home from '../components/Home';
import Nav from '../components/Nav';
import LoadingMessage from '../components/LoadingMessage';
import NotFound from '../components/NotFound';

// Font Awesome components
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faEllipsisH, faPlus, faSearch as faSearchR, faSmile, faReceipt, faTasks,
          faCheckCircle as faCheckCircleR, faCheckSquare, faStar, faTachometer, faThList,
        faSitemap as faSitemapR, faExternalLinkSquare, faFile, faSpinnerThird, faFileAlt as faFileAltR
      } from '@fortawesome/pro-regular-svg-icons';

import { faChevronDown, faTimes, faCommentAlt, faCommentsAlt, faSlidersV,
          faPalette, faSitemap, faQuestionSquare, faMapMarkerAlt, faFileAlt as faFileAltL,
          faShareAlt, faArrowToBottom as faArrowToBottomL, faMinusCircle } from '@fortawesome/pro-light-svg-icons';

import { faCaretDown, faCircle, faQuestionCircle, faExternalLink, faUserAlt,
          faTag, faSearch, faPen, faFileAlt, faTrashAlt, faPlay, faBell, faUserCircle,
          faHeartCircle, faUsers, faSignOut, faSlidersV as faSlidersVS, faPalette as faPaletteS,
          faSitemap as faSitemapS, faQuestionSquare as faQuestionSquareS, faMapMarkerAlt as faMapMarkerAltS,
          faTimes as faTimesS, faInfoCircle, faCheckCircle, faBan, faExclamationTriangle,
          faCheck as faCheckS, faBurn, faArrowToBottom } from '@fortawesome/pro-solid-svg-icons';

import { faJsSquare} from '@fortawesome/free-brands-svg-icons';
// "@fortawesome/pro-regular-svg-icons": "^5.5.0",
// "@fortawesome/pro-solid-svg-icons": "^5.5.0",

library.add(
  faCheck, faEllipsisH, faPlus, faSearchR, faSmile, faReceipt, faJsSquare, faTasks, faCheckCircleR, faCheckSquare,
  faStar, faTachometer, faSitemapR, faExternalLinkSquare, faFile, faSpinnerThird, faFileAltR, faMinusCircle, faThList, faChevronDown, faTimes, faCommentAlt, faCommentsAlt, faFileAltL,
  faCaretDown, faCircle, faQuestionCircle, faExternalLink, faTag, faSearch, faPen, faFileAlt, faShareAlt, faArrowToBottomL, faTrashAlt, faPlay,
  faSlidersV, faPalette, faSitemap, faQuestionSquare, faMapMarkerAlt, faBell, faUserAlt, faUserCircle, faHeartCircle,
  faUsers, faSignOut, faSlidersVS, faPaletteS, faSitemapS, faQuestionSquareS, faMapMarkerAltS, faTimesS,
  faInfoCircle, faCheckCircle, faBan, faExclamationTriangle, faCheckS, faBurn, faArrowToBottom
);

// Routes
const Color = (lazy(() => (  import('../routes/Color'))));
const Typography = (lazy(() => (  import('../routes/Typography'))));
const Spacing = (lazy(() => (  import('../routes/Spacing'))));
const Layer = (lazy(() => (  import('../routes/Layer'))));
const Grid = (lazy(() => (  import('../routes/Grid'))));
const AlertPage = (lazy(() => (  import('../routes/Alert'))));
const FormPage = (lazy(() => (  import('../routes/Form'))));
const Buttons = (lazy(() => (  import('../routes/Buttons'))));
const CheckboxPage = (lazy(() => (  import('../routes/Checkbox'))));
const DropdownPage = (lazy(() => (  import('../routes/Dropdown'))));
const RadioPage = (lazy(() => (  import('../routes/Radio'))));
const ImageButtonPage = (lazy(() => (  import('../routes/ImageButton'))));
const TogglePage = (lazy(() => (  import('../routes/Toggle'))));
const TextFieldPage = (lazy(() => (  import('../routes/TextFields'))));
const OverflowPage = (lazy(() => (  import('../routes/Overflow'))));
const TagPage = (lazy(() => (  import('../routes/Tag'))));
const TooltipPage = (lazy(() => (  import('../routes/Tooltip'))));
const ListIconPage = (lazy(() => (  import('../routes/ListIcon'))));
const ListSimplePage = (lazy(() => (  import('../routes/ListSimple'))));
const ListClickPathPage = (lazy(() => (  import('../routes/ListClickPath'))));
const ListTasksQuestionsPage = (lazy(() => (  import('../routes/ListTasksQuestions'))));
const ListDataTablePage = (lazy(() => (  import('../routes/ListDataTable'))));
const ListMainDashboardPage = (lazy(() => (  import('../routes/ListMainDashboard'))));
const VideoParticipantsListPage = (lazy(() => (  import('../routes/VideoParticipantsList'))));
const ListSpecificParticipantPage = (lazy(() => (  import('../routes/ListSpecificParticipant'))));
const NavigationPrimaryPage = (lazy(() => (  import('../routes/NavigationPrimary'))));
const NavigationSecondaryPage = (lazy(() => (  import('../routes/NavigationSecondary'))));
const UserIconPage = (lazy(() => (  import('../routes/UserIcon'))));
const ContextHeaderPage = (lazy(() => (  import('../routes/ContextHeader'))));
const ReportSummaryPage = (lazy(() => (  import('../routes/ReportSummary'))));
const SegmentPage = (lazy(() => (  import('../routes/Segment'))));
const ChartPiePage = (lazy(() => (  import('../routes/ChartPie'))));
const ChartGaugePage = (lazy(() => (  import('../routes/ChartGauge'))));
const ChartBarPage = (lazy(() => (  import('../routes/ChartBar'))));
const ChartClickstreamPage = (lazy(() => (  import('../routes/ChartClickstream'))));
const StudyCreationPage = (lazy(() => (  import('../routes/StudyCreation'))));

class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <div className="ds-app">
          <Nav />
          <div className="container" id='id-main-content'>
            <Suspense fallback={<LoadingMessage />}>
            <Switch>
              <Route exact path="/" component={Home}  />
              <Route path="/style/color/" render={(props) => <Color {...props} location="routes/Color" />} />
              <Route path="/style/typography/" render={(props) => <Typography {...props} location="routes/Typography" />} />
              <Route path="/style/spacing/" render={(props) => <Spacing {...props} location="routes/Spacing" />} />
              <Route path="/style/layer/" render={(props) => <Layer {...props} location="routes/Layer" />} />
              <Route path="/style/grid/" render={(props) => <Grid {...props} location="routes/Grid" />} />
              <Route path="/components/alert/" render={(props) => <AlertPage {...props} location="routes/Alert" />} />
              <Route path="/components/form/" render={(props) => <FormPage {...props} location="routes/Form" />} />
              <Route path="/components/buttons/" render={(props) => <Buttons {...props} location="routes/Buttons" />} />
              <Route path="/components/imagebuttons/" render={(props) => <ImageButtonPage {...props} location="routes/ImageButtonPage" />} />
              <Route path="/components/checkbox/" render={(props) => <CheckboxPage {...props} location="routes/Checkbox" />} />
              <Route path="/components/dropdown/" render={(props) => <DropdownPage {...props} location="routes/Dropdown" />} />
              <Route path="/components/radio/" render={(props) => <RadioPage {...props} location="routes/Radio" />} />
              <Route path="/components/toggle/" render={(props) => <TogglePage {...props} location="routes/Toggle" />} />
              <Route path="/components/text-fields/" render={(props) => <TextFieldPage {...props} location="routes/TextField" />} />
              <Route path="/components/overflow/" render={(props) => <OverflowPage {...props} location="routes/Overflow" />} />
              <Route path="/components/tag/" render={(props) => <TagPage {...props} location="routes/Tag" />} />
              <Route path="/components/tooltip/" render={(props) => <TooltipPage {...props} location="routes/Tooltip" />} />
              <Route path="/components/list-icon/" render={(props) => <ListIconPage {...props} location="routes/ListIcons" />} />
              <Route path="/components/list-simple/" render={(props) => <ListSimplePage {...props} location="routes/ListSimple" />} />
              <Route path="/components/list-click-path/" render={(props) => <ListClickPathPage {...props} location="routes/ListClickPath" />} />
              <Route path="/components/list-tasks-questions/" render={(props) => <ListTasksQuestionsPage {...props} location="routes/ListTasksQuestions" />} />
              <Route path="/components/list-data-table/" render={(props) => <ListDataTablePage {...props} location="routes/ListDataTable" />} />
              <Route path="/components/list-main-dashboard/" render={(props) => <ListMainDashboardPage {...props} location="routes/ListMainDashboard" />} />
              <Route path="/components/list-video-participants/" render={(props) => <VideoParticipantsListPage {...props} location="routes/VideoParticipantsList" />} />
              <Route path="/components/list-specific-participant/" render={(props) => <ListSpecificParticipantPage {...props} location="routes/ListSpecificParticipant" />} />
              <Route path="/components/navigation-primary/" render={(props) => <NavigationPrimaryPage {...props} location="routes/NavigationPrimary" />} />
              <Route path="/components/navigation-secondary/" render={(props) => <NavigationSecondaryPage {...props} location="routes/NavigationSecondary" />} />
              <Route path="/components/user-icon/" render={(props) => <UserIconPage {...props} location="routes/UserIcon" />} />
              <Route path="/components/context-header/" render={(props) => <ContextHeaderPage {...props} location="routes/ContextHeader" />} />
              <Route path="/components/report-summary/" render={(props) => <ReportSummaryPage {...props} location="routes/ReportSummary" />} />
              <Route path="/components/segment/" render={(props) => <SegmentPage {...props} location="routes/Segment" />} />
              <Route path="/components/chart-pie/" render={(props) => <ChartPiePage {...props} location="routes/ChartPie" />} />
              <Route path="/components/chart-gauge/" render={(props) => <ChartGaugePage {...props} location="routes/ChartGauge" />} />
              <Route path="/components/chart-bar/" render={(props) => <ChartBarPage {...props} location="routes/ChartBar" />} />
              <Route path="/components/chart-clickstream/" render={(props) => <ChartClickstreamPage {...props} location="routes/ChartClickstream" />} />
              <Route path="/components/page/study-creation/" render={(props) => <StudyCreationPage {...props} location="routes/StudyCreation" />} />
              <Route component={NotFound} />
            </Switch>
            </Suspense>
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
