
import { SchemaLink } from 'apollo-link-schema';
import { makeExecutableSchema } from 'graphql-tools';
import { InMemoryCache } from 'apollo-cache-inmemory';

// Defs, used locally by the client
import typeDefs from './type_defs.js';
import resolvers from './resolvers.js';

// Build a local client
import { ApolloClient } from 'apollo-client';

// Mock GraphQL, thus the design system can use the same way as the front
// uses.  The mock data is defined within components/data/resolvers.js
// while the schema is defined within components/data/type_defs.js, which
// is generated via a django manament command as documented with in said
// file.

// Imports are here, since the Front end build will not want all these
// files bundled in the final build.

const cache = new InMemoryCache();
const executableSchema = makeExecutableSchema({
    typeDefs: typeDefs,
    resolvers: resolvers
});
const link = new SchemaLink({ schema: executableSchema })

const Client = new ApolloClient({ ssrMode: true, link: link, cache: cache });

export default Client;
