
// data from staging assumed user kaisa.soininen@yogaia.com
const participants = [
  {
    id: '2797895',
    uuid: "01610b5d-5708-74b5-2194-6025da2bfa44",
    name: 'Sam',
    dateTimeStart: 1516274521,
    dateTimeEnd: 1516274669,
    duration: '2m28s',
    note: null,
    ipAddress: "63.231.250.22",
    deviceCategory: "Desktop",
    browser: {
      name: "Firefox"
    },
    statistics: {
      success: 0,
      failure: 0,
      abandon: 1,
      complete: 0
    },
    os: {
      name: "Mac OSX",
      version: "10.14"
    },
    address: {
      country: "AU",
      region: "Victoria",
      city: "Melboune"
    },
    hidden: false,
    userTest: {
      uuid: "00000000-15bd-ed31-feb0-e2ea34524130",
      id: "16544576"
    },
    videoRecording: {
      participant: {
        id: '2797895'
      },
      uuid: "01610b5f-9bd0-4b4c-3f9c-39dd82a25602",
      kind: "U",
      archiveId: "6c1e8269-6c39-4fff-bf07-597ee68c20ce",
      created: 1516274670,
      modified: 1516274987,
      step: "D",
      error: null,
      hasAudio: false,
      hasFaceVideo: true,
      hasScreenVideo: true,
      hasOverlayVideo: true,
      hasTranscoded: true,
      tags: [
        {
          uuid: "7ab85012-d70a-4ae9-ab2b-3b7388010a27",
          "name": "usability difficulty with the loop11 flyout"
        }
      ]
    },
   taskresultSet: [
     {
       "uuid": "016858ee-6a8a-4cdb-4249-4a59d39bdfab",
       "statistics": "{\"state\": \"complete\", \"lostness\": null, \"page_views\": 2, \"time_taken\": 14.418143}"
     }
   ]
  },
  {
    id: '2797898',
    userTest: {
      uuid: "00000000-15bd-ed31-feb0-e2ea34524130",
      id: "16544576"
    },
    uuid: "01610b63-4029-be19-c1fb-5761d4691316",
    name: 'Participants 2',
    browser: {
      name: "Firefox"
    },
    os: {
      name: "Mac OSX",
      version: "10.14"
    },
    address: {
      country: "AU",
      region: "Victoria",
      city: "Melboune"
    },
    dateTimeStart: 1516274909,
    dateTimeEnd: 1516275032,
    duration: '2m3s',
    note: null,
    ipAddress: "165.84.229.101",
    deviceCategory: "Desktop",
    statistics: {
      success: 10,
      failure: 3,
      abandon: 1,
      complete: 0
    },
    hidden: false,
    videoRecording: {
      participant: {
        id: '2797898'
      },
      uuid: "01610b65-2a8b-9eb2-367e-a17cfe18513f",
      kind: "U",
      archiveId: "d250eb33-3c59-4124-8466-2d6c79de1579",
      created: 1516275034,
      modified: 1516275266,
      step: "D",
      error: null,
      hasAudio: false,
      hasFaceVideo: true,
      hasScreenVideo: true,
      hasOverlayVideo: true,
      hasTranscoded: true,
      tags: []
    }
  },
  {
    id: '2797899',
    userTest: {
      uuid: "00000000-15bd-ed31-feb0-e2ea34524130",
      id: "16544576"
    },
    uuid: "01610b65-0de7-7fcd-5d80-a856c976e921",
    name: 'Participants 3',
    browser: {
      name: "Firefox"
    },
    os: {
      name: "Mac OSX",
      version: "10.14"
    },
    address: {
      country: "AU",
      region: "Victoria",
      city: "Melboune"
    },
    dateTimeStart: 1516275027,
    dateTimeEnd: null,
    duration: null,
    note: null,
    ipAddress: "68.118.148.56",
    deviceCategory: "Desktop",
    statistics: {
      success: 10,
      failure: 3,
      abandon: 1,
      complete: 0
    },
    hidden: true,
    videoRecording: {
      participant: {
        id: '2797899'
      },
      uuid: "01610b69-70a7-396e-064a-2addefbbccf1",
      kind: "U",
      archiveId: "f1d5af8a-f8f1-4be6-9a8a-753c08916154",
      created: 1516275314,
      modified: 1516275757,
      step: "D",
      error: null,
      hasAudio: false,
      hasFaceVideo: true,
      hasScreenVideo: true,
      hasOverlayVideo: true,
      hasTranscoded: true,
      tags: []
    }
  },
  {
    id: "2797900",
    userTest: {
      uuid: "11111111-15bd-ed31-feb0-e2ea34524131",
      id: "8754423"
    },
    uuid: "01610b66-c695-8ebc-2d36-7d5e6dc23fda",
    name: 'Participants 1',
    browser: {
      name: "Firefox"
    },
    os: {
      name: "Mac OSX",
      version: "10.14"
    },
    address: {
      country: "AU",
      region: "Victoria",
      city: "Melboune"
    },
    dateTimeStart: 1516275140,
    dateTimeEnd: 1516275505,
    duration: '6m5s',
    note: null,
    ipAddress: "66.18.52.187",
    deviceCategory: "Desktop",
    statistics: {
      success: 10,
      failure: 3,
      abandon: 1,
      complete: 0
    },
    hidden: false,
    videoRecording: {
      participant: {
        id: "2797900"
      },
      uuid: "01610b6c-603f-eeb5-95ac-09f7a4cf08d2",
      kind: "U",
      archiveId: "e0d9700f-c267-4360-84b6-ade3b08a0afe",
      created: 1516275507,
      modified: 1516276451,
      step: "D",
      error: null,
      hasAudio: false,
      hasFaceVideo: true,
      hasScreenVideo: true,
      hasOverlayVideo: true,
      hasTranscoded: true,
      tags: []
    }
  },
  {
    id: "2797902",
    userTest: {
      uuid: "11111111-15bd-ed31-feb0-e2ea34524131",
      id: "8754423"
    },
    uuid: "01610b68-5848-85cc-a390-b24da942fc82",
    name: 'Participants 2',
    browser: {
      name: "Firefox"
    },
    os: {
      name: "Mac OSX",
      version: "10.14"
    },
    address: {
      country: "AU",
      region: "Victoria",
      city: "Melboune"
    },
    dateTimeStart: 1516275243,
    dateTimeEnd: null,
    note: null,
    ipAddress: "73.211.14.85",
    deviceCategory: "Desktop",
    statistics: {
      success: 10,
      failure: 3,
      abandon: 1,
      complete: 0
    },
    hidden: false,
    videoRecording: {
      participant: {
        id: "2797902"
      },
      uuid: "01610b6b-e8c4-86f8-c5a4-970c905c51ff",
      kind: "U",
      archiveId: "5886198a-171e-4a57-af2b-ed2300de727c",
      created: 1516275476,
      modified: 1516275614,
      step: "D",
      error: null,
      hasAudio: false,
      hasFaceVideo: true,
      hasScreenVideo: true,
      hasOverlayVideo: true,
      hasTranscoded: true,
      tags: []
    }
  },
  {
    id: "2797903",
    userTest: {
      uuid: "11111111-15bd-ed31-feb0-e2ea34524131",
      id: "8754423"
    },
    uuid: "01610b69-af51-b272-040a-c5571503838f",
    name: 'Participants 3',
    browser: {
      name: "Firefox"
    },
    os: {
      name: "Mac OSX",
      version: "10.14"
    },
    address: {
      country: "AU",
      region: "Victoria",
      city: "Melboune"
    },
    dateTimeStart: 1516275331,
    dateTimeEnd: null,
    note: null,
    ipAddress: "68.118.148.56",
    deviceCategory: "Desktop",
    statistics: {
      success: 10,
      failure: 3,
      abandon: 1,
      complete: 0
    },
    hidden: false,
    videoRecording: {
      participant: {
        id: "2797903"
      },
      uuid: "01610b6a-39fe-bbbb-7043-079401aefb8f",
      kind: "U",
      archiveId: "da374925-81ed-4078-b47f-0020a216d55b",
      created: 1516275366,
      modified: 1516275446,
      step: "D",
      error: null,
      hasAudio: false,
      hasFaceVideo: true,
      hasScreenVideo: true,
      hasOverlayVideo: true,
      hasTranscoded: true,
      tags: []
    }
  },
  {
    id: "2797904",
    userTest: {
      uuid: "11111111-15bd-ed31-feb0-e2ea34524131",
      id: "8754423"
    },
    uuid: "01610b6a-ee8c-4296-8ac8-a8cfd595cc22",
    name: 'Participants 4',
    browser: {
      name: "Firefox"
    },
    os: {
      name: "Mac OSX",
      version: "10.14"
    },
    address: {
      country: "AU",
      region: "Victoria",
      city: "Melboune"
    },
    dateTimeStart: 1516275412,
    dateTimeEnd: 1516275800,
    note: null,
    ipAddress: "68.224.152.65",
    deviceCategory: "Desktop",
    statistics: {
      success: 10,
      failure: 3,
      abandon: 1,
      complete: 0
    },
    hidden: false,
    videoRecording: {
      participant: {
        id: "2797904"
      },
      uuid: "01610b70-e1e0-f662-96be-595e766db4a4",
      kind: "U",
      archiveId: "78dcf623-1781-4d03-9d93-642b99a45240",
      created: 1516275802,
      modified: 1516276592,
      step: "D",
      error: null,
      hasAudio: false,
      hasFaceVideo: true,
      hasScreenVideo: true,
      hasOverlayVideo: true,
      hasTranscoded: true,
      tags: []
    }
  },
  {
    id: "2797905",
    userTest: {
      uuid: "11111111-15bd-ed31-feb0-e2ea34524131",
      id: "8754423"
    },
    uuid: "01610b6b-2092-e194-80e6-56606f56bc66",
    name: 'Participants 5',
    browser: {
      name: "Firefox"
    },
    os: {
      name: "Mac OSX",
      version: "10.14"
    },
    address: {
      country: "AU",
      region: "Victoria",
      city: "Melboune"
    },
    dateTimeStart: 1516275425,
    dateTimeEnd: 1516275522,
    note: null,
    ipAddress: "98.248.29.27",
    deviceCategory: "Desktop",
    statistics: {
      success: 10,
      failure: 3,
      abandon: 1,
      complete: 0
    },
    hidden: false,
    videoRecording: {
      participant: {
        id: "2797905"
      },
      uuid: "01610b6c-a06c-c8da-9250-e9283e544ce5",
      kind: "U",
      archiveId: "f3859d57-5823-4ad2-838f-add901c941ad",
      created: 1516275523,
      modified: 1516275922,
      step: "D",
      error: null,
      hasAudio: false,
      hasFaceVideo: true,
      hasScreenVideo: true,
      hasOverlayVideo: true,
      hasTranscoded: true,
      tags: []
    }
  },
  {
    id: "2797907",
    userTest: {
      uuid: "11111111-15bd-ed31-feb0-e2ea34524131",
      id: "8754423"
    },
    name: 'Participants 6',
    browser: {
      name: "Firefox"
    },
    os: {
      name: "Mac OSX",
      version: "10.14"
    },
    address: {
      country: "AU",
      region: "Victoria",
      city: "Melboune"
    },
    uuid: "01610b6d-a937-0843-a752-3d2e3052eb75",
    dateTimeStart: 1516275591,
    dateTimeEnd: 1516275956,
    note: null,
    ipAddress: "74.138.41.190",
    deviceCategory: "Desktop",
    statistics: {
      success: 10,
      failure: 3,
      abandon: 1,
      complete: 0
    },
    hidden: false,
    videoRecording: {
      participant: {
        id: "2797907"
      },
      uuid: "01610b73-3f62-fc69-9b56-fb4deea8602b",
      kind: "U",
      archiveId: "a2ad26a4-e336-452d-9b53-f5350a787c10",
      created: 1516275957,
      modified: 1516277055,
      step: "D",
      error: null,
      hasAudio: false,
      hasFaceVideo: true,
      hasScreenVideo: true,
      hasOverlayVideo: true,
      hasTranscoded: true,
      tags: []
    }
  },
  {
    id: "2797908",
    userTest: {
      uuid: "11111111-15bd-ed31-feb0-e2ea34524131",
      id: "8754423"
    },
    uuid: "01610b6f-7d42-3858-0711-8966cf5e4ef0",
    name: 'Participants 7',
    browser: {
      name: "Firefox"
    },
    os: {
      name: "Mac OSX",
      version: "10.14"
    },
    address: {
      country: "AU",
      region: "Victoria",
      city: "Melboune"
    },
    dateTimeStart: 1516275711,
    dateTimeEnd: null,
    note: null,
    ipAddress: "73.211.14.85",
    deviceCategory: "Desktop",
    statistics: {
      success: 10,
      failure: 3,
      abandon: 1,
      complete: 0
    },
    hidden: false,
    videoRecording: {
      participant: {
        id: "2797908"
      },
      uuid: "01610b73-4c0c-d9a3-5e12-dd0a6e7dc0f0",
      kind: "U",
      archiveId: "974a8ebd-9de7-4894-b1bd-21853b4e4314",
      created: 1516275960,
      modified: 1516276875,
      step: "D",
      error: null,
      hasAudio: false,
      hasFaceVideo: true,
      hasScreenVideo: true,
      hasOverlayVideo: true,
      hasTranscoded: true,
      tags: []
    }
  }
];

export default participants;
