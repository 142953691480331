const deviceOS = [
      {
        id: 1,
        uuid: '12345678901',
        parentUuid: '12345678901',
        name: "Any Desktop/Laptop"
      },
      {
        id: 2,
        uuid: '12345678902',
        parentUuid: '12345678901',
        name: "Mac OS"
      },
      {
        id: 3,
        uuid: '12345678903',
        parentUuid: '12345678901',
        name: "Windows",
      },
      {
        id: 4,
        uuid: '12345678904',
        parentUuid: '12345678901',
        name: "Chrome OS"
      },
      {
        id: 5,
        uuid: '12345678905',
        parentUuid: '12345678901',
        name: "Linux"
      },
      {
        id: 6,
        uuid: '12345678906',
        parentUuid: '12345678902',
        name: "Any Phone"
      },
      {
        id: 7,
        uuid: '12345678907',
        parentUuid: '12345678902',
        name: "iOS"
      },
      {
        id: 8,
        uuid: '12345678902',
        parentUuid: '12345678902',
        name: "Windows",
      },
      {
        id: 9,
        uuid: '12345678903',
        parentUuid: '12345678902',
        name: "Android"
      },
      {
        id: 10,
        uuid: '12345678908',
        parentUuid: '12345678903',
        name: "Any Tablet"
      },
      {
        id: 11,
        uuid: '12345678909',
        parentUuid: '12345678903',
        name: "iOS"
      },
      {
        id: 12,
        uuid: '12345678910',
        parentUuid: '12345678903',
        name: "Windows",
      },
      {
        id: 13,
        uuid: '12345678911',
        parentUuid: '12345678903',
        name: "Android"
      }
    ]

export default deviceOS;
