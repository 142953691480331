let userTests = [
    {
        id: '16544',
        uuid: "00000000-15bd-ed31-feb0-e2ea34524130",
        publicTitle: "Sony Lapdogs Are Still Part Of The 00000000",
        active: true,
        launched: true,
        moderated: false,
        inviteParticipants: 'link',
        audioOption: 'D',
        faceOption: 'O',
        screenOption: 'M',
        created: 1541134094,
        modified: 1548048094,
        launchedDate: null,
        participantCount: 3,
        maximumParticipants: 100,
        embeddingJavascript: true,
        urlStart: 'https://example-website.com',
        tags: [
            {
              uuid: "771ef145-d485-4d3b-986e-88b52197664b",
              name: "UI"
            },
            {
              uuid: "0f91ff96-cf96-4dd2-8606-23d7d600c2ea",
              name: "Old"
            }
          ],
        results: {
            success: null,
            failure: null,
            abandon: null,
            nps: null,
            sus: null
        },
        tasks: [
          {
            uuid: "2c900c3a-4693-456d-87f9-d10cb8b5faba",
            name: "Task 1",
            scenario: "<p>Should now be on this page -&nbsp;https://true.intent.loop11.com/clients.html</p>"
          }
        ]
    },
    {
        id: '8754423',
        uuid: "11111111-15bd-ed31-feb0-e2ea34524131",
        publicTitle: "Discover Little Known Secrets For Successful Living",
        active: true,
        launched: true,
        moderated: false,
        created: 1545184094,
        modified: 1543058094,
        launchedDate: 1648048094,
        participantCount: 11,
        maximumParticipants: 90,
        tags: [
            {
              uuid: "0f91ff96-cf96-4dd2-8606-23d7d600c2ea",
              name: "Old"
            },
            {
              uuid: "7c604e99-3880-4675-af33-6247c7bcbed7",
              name: "Renegade Cat Fighters"
            },
            {
              uuid: "53e0703d-8a15-4870-a224-378187e8d906",
              name: "Mighty Blighters"
            }
          ],
        results: {
            success: 22,
            failure: 34,
            abandon: 41,
            nps: {score: 9},
            sus: {score: 100},
            lostness: "",
            participants: "11/90"
        }
    }, {
        id: '9727427',
        uuid: "22222222-15bd-ed31-feb0-e2ea34524132",
        publicTitle: "Fleas Are Peoples Too!",
        active: false,
        launched: true,
        moderated: true,
        created: 1547164094,
        modified: 1548048094,
        launchedDate: 1648048094,
        participantCount: 399,
        maximumParticipants: 1000,
        tags: [],
        results: {
            success: 11,
            failure: 66,
            abandon: 2,
            complete: 0,
            nps: {score: -90},
            sus: {score: 6},
            lostness: 0.8,
            participants: "399/1000"
        }
    }, {
        id: '9772425',
        uuid: "33333333-1111-ed31-feb0-e2ea34524132",
        publicTitle: "The big study ran on mobile iOs devices",
        active: true,
        launched: true,
        moderated: false,
        inviteParticipants: 'link',
        audioOption: 'D',
        faceOption: 'O',
        screenOption: 'M',
        created: 1541134094,
        modified: 1548048094,
        launchedDate: null,
        participantCount: 3,
        maximumParticipants: 100,
        embeddingJavascript: true,
        urlStart: 'https://example-website.com',
        tags: [],
        results: {
            success: 11,
            failure: 66,
            abandon: 2,
            lostness: 0.8,
            participants: "399/1000",
            devices: "[{\"count\": 22, \"value\": \"Desktop\"}, {\"count\": 0, \"value\": \"Mobile\"}, {\"count\": 0, \"value\": \"Tablet\"}]",
            browsers: "[{\"count\": 22, \"value\": \"Chrome\"}]",
            oses: "[{\"count\": 19, \"value\": \"Windows 10\"}, {\"count\": 2, \"value\": \"Mac OSX\"}, {\"count\": 1, \"value\": \"Windows 8.1\"}]",
            addresses: "[{\"count\": 1, \"value\": \"Albuquerque, New Mexico, United States of America\"}, {\"count\": 1, \"value\": \"Baltimore, Maryland, United States of America\"}, {\"count\": 1, \"value\": \"Canandaigua, New York, United States of America\"}, {\"count\": 1, \"value\": \"Charlottesville, Virginia, United States of America\"}, {\"count\": 1, \"value\": \"Chattanooga, Tennessee, United States of America\"}, {\"count\": 22, \"value\": \"other\"}]",
        },
        tasks: [
          {
            id: "161271",
            order: 1,
            scenario: 'Look for Poo bear on this page',
            statistics: {
              success: 0,
              failure: 0,
              abandon: 0,
              complete: 0,
              avgDuration: "0s",
              avgPageViews: "0.0"
            },
            uuid: "6581fc2f-0512-4e38-81e5-d9a932f6ff95",
            name: "Find the bear"
          }
        ],
        questions: [
          {
            id: '175488',
            uuid: 'a8668959-3ecd-433f-bb37-2513abb31b57',
            order: 2,
            question: "Overall, how easy or difficult was it to find this information?",
            statistics: {
              netpromoterscore: null,
              systemusabilityscore: null
            }
          },
          {
            id: '175489',
            order: 3,
            question: "How long has Fyre Agency been operating?",
            statistics: {
              netpromoterscore: null,
              systemusabilityscore: null
            }
          },
          {
            id: '175490',
            order: 4,
            question: "How likely are you to use mobile payments yourself in the next 6 months?",
            statistics: {
              netpromoterscore: null,
              systemusabilityscore: null
            }
          },
          {
            id: '175491',
            order: 5,
            question: "Based on your experience with the Fyre Agency website today, how likely would you be to recommend it to others?",
            statistics: {
              netpromoterscore: null,
              systemusabilityscore: null
            }
          }
        ],
        "participants": [
          {
            id: '2797895',
            name: "Participant 1",
            statistics: {
              success: 1,
              complete: 0,
              abandon: 0,
              failure: 2
            }
          },
          {
            id: '2797898',
            name: "Participant 2",
            statistics: {
              success: 1,
              complete: 0,
              abandon: 0,
              failure: 2
            }
          },
          {
            id: '2797899',
            name: "Participant 3",
            statistics: {
              success: 0,
              complete: 0,
              abandon: 2,
              failure: 0
            }
          },
          {
            id: "2797900",
            name: "Participant 4",
            statistics: {
              success: 2,
              complete: 0,
              abandon: 0,
              failure: 1
            }
          },
          {
            id: "2797902",
            name: "Participant 5",
            statistics: {
              success: 1,
              complete: 0,
              abandon: 0,
              failure: 0
            }
          },
          {
            id: "2797903",
            name: "Participant 6",
            statistics: {
              success: 0,
              complete: 0,
              abandon: 1,
              failure: 0
            }
          },
          {
            id: "2797904",
            name: "Participant 7",
            statistics: {
              success: 1,
              complete: 0,
              abandon: 0,
              failure: 2
            }
          },
          {
            id: "2797905",
            name: "Participant 8",
            statistics: {
              success: 2,
              complete: 0,
              abandon: 0,
              failure: 1
            }
          },
          {
            id: "2797907",
            name: "Participant 9",
            statistics: {
              success: 0,
              complete: 0,
              abandon: 0,
              failure: 3
            }
          },
          {
            id: "2797908",
            name: "Participant 10",
            statistics: {
              success: 2,
              complete: 0,
              abandon: 0,
              failure: 1
            }
          },
          {
            id: "2797909",
            name: "Participant 11",
            statistics: {
              success: 2,
              complete: 0,
              abandon: 1,
              failure: 0
            }
          },
          {
            id: "2797910",
            name: "Participant 12",
            statistics: {
              success: 1,
              complete: 0,
              abandon: 1,
              failure: 1
            }
          },
          {
            id: "2797911",
            name: "Participant 13",
            statistics: {
              success: 2,
              complete: 0,
              abandon: 0,
              failure: 1
            }
          },
          {
            id: "2797912",
            name: "Participant 14",
            statistics: {
              success: 2,
              complete: 0,
              abandon: 0,
              failure: 1
            }
          },
          {
            id: "2797915",
            name: "Participant 15",
            statistics: {
              success: 1,
              complete: 0,
              abandon: 0,
              failure: 2
            }
          },
          {
            id: "2797921",
            name: "Participant 16",
            statistics: {
              success: 2,
              complete: 0,
              abandon: 0,
              failure: 1
            }
          },
          {
            id: "2797922",
            name: "Participant 17",
            statistics: {
              success: 1,
              complete: 0,
              abandon: 1,
              failure: 1
            }
          },
          {
            id: "2797923",
            name: "Participant 18",
            statistics: {
              success: 2,
              complete: 0,
              abandon: 0,
              failure: 1
            }
          },
          {
            id: "2797924",
            name: "Participant 19",
            statistics: {
              success: 0,
              complete: 0,
              abandon: 1,
              failure: 0
            }
          },
          {
            id: "2797926",
            name: "Participant 20",
            statistics: {
              success: 3,
              complete: 0,
              abandon: 0,
              failure: 0
            }
          },
          {
            id: "2797932",
            name: "Participant 21",
            statistics: {
              success: 1,
              complete: 0,
              abandon: 0,
              failure: 2
            }
          },
          {
            id: "2797933",
            name: "Participant 22",
            statistics: {
              success: 2,
              complete: 0,
              abandon: 0,
              failure: 1
            }
          },
          {
            id: "2797938",
            name: "Participant 23",
            statistics: {
              success: 1,
              complete: 0,
              abandon: 0,
              failure: 2
            }
          },
          {
            id: "2797940",
            name: "Participant 24",
            statistics: {
              success: 0,
              complete: 0,
              abandon: 0,
              failure: 1
            }
          },
          {
            id: "2797941",
            name: "Participant 25",
            statistics: {
              success: 1,
              complete: 0,
              abandon: 0,
              failure: 2
            }
          },
          {
            id: "2797957",
            name: "Participant 26",
            statistics: {
              success: 2,
              complete: 0,
              abandon: 1,
              failure: 0
            }
          },
          {
            id: "2797965",
            name: "Participant 27",
            statistics: {
              success: 0,
              complete: 0,
              abandon: 0,
              failure: 3
            }
          },
          {
            id: "2797967",
            name: "Participant 28",
            statistics: {
              success: 1,
              complete: 0,
              abandon: 0,
              failure: 2
            }
          },
          {
            id: "2797978",
            name: "Participant 29",
            statistics: {
              success: 1,
              complete: 0,
              abandon: 0,
              failure: 2
            }
          },
          {
            id: "2797981",
            name: "Participant 30",
            statistics: {
              success: 2,
              complete: 0,
              abandon: 1,
              failure: 0
            }
          }
        ]

    }
]



export default userTests;
