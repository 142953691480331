import React from 'react';
import ReactDOM from 'react-dom';

import { ApolloProvider } from 'react-apollo';

import * as serviceWorker from './serviceWorker';

// Either a real client, or the mock one for the Design-System
const client = (process.env.REACT_APP_TARGET === 'Front-End') ? require('./live/apollo_client.js').default : require('./mock/apollo_client.js').default;

// One of two Apps are build
const App = (process.env.REACT_APP_TARGET === 'Front-End') ? require('./containers/FrontEnd').default : require('./containers/App').default;

ReactDOM.render(
  // So that all wrapper components can make graphQL queries using <Query ...>
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
