const regions = [
{name: "Victoria", countryCode: "AU", id: 1},
{name: "New South Wales", countryCode: "AU", id: 2},
{name: "Queensland", countryCode: "AU", id: 3},
{name: "South Australia", countryCode: "AU", id: 4},
{name: "Western Australia", countryCode: "AU", id: 5},
{name: "Canberra", countryCode: "AU", id: 6},
{name: "Northern Territory", countryCode: "AU", id: 7},
{name: "California", countryCode: "US", id: 8},
{name: "New York", countryCode: "US", id: 9},
{name: "Arizona", countryCode: "US", id: 10},
{name: "Texas", countryCode: "US", id: 11},
{name: "Floria", countryCode: "US", id: 12},
{name: "North Carolina", countryCode: "US", id: 13},
{name: "Ohio", countryCode: "US", id: 14},
{name: "Pensilvania", countryCode: "US", id: 15},
{name: "Aukland", countryCode: "NZ", id: 16},
{name: "Christchurch", countryCode: "NZ", id: 17},
{name: "Queenstown", countryCode: "NZ", id: 18},
{name: "Killer Kiwi Birds", countryCode: "NZ", id: 19}
]

export default regions;
