export const questions = [
      {
        id: 123456780,
        userTest_Id: 16544,
        order: 3,
        uuid: "01624c98-e7f0-3d52-9333-62724e21f7a8",
        name: "Ease of completion question",
        question: "<p>How easy did you find it to complete this question?</p>",
        questionType: "MULTICHOICE1",
        randomise: true,
        require: true,
        addOther: true,
        otherText: "Other, please specify",
        answers: [
          {id: 0, value: "Very Easy", placeholder: "", order: 1},
          {id: 1, value: "Easy", placeholder: "", order: 2},
          {id: 2, value: "Neither easy nor difficult", placeholder: "", order: 3},
          {id: 3, value: "Difficult", placeholder: "", order: 4},
          {id: 4, value: "Very difficult", placeholder: "", order: 5}
        ],
        identifier: "QUESTION"
      },
      {
        id: 123456781,
        userTest_Id: 16544,
        order: 2,
        uuid: "01624c98-e7f0-3d52-9333-62724e21f7a7",
        name: "Words that describe my experience",
        question: "<p>Which of the following adjectives would you use to describe this website?</p>",
        questionType: "MULTICHOICEMULTI",
        randomise: true,
        require: true,
        addOther: false,
        otherText: "Other, please specify",
        answers: [
          {id: 0, value: "Professional", placeholder: "", order: 1},
          {id: 1, value: "Cluttered", placeholder: "", order: 2},
          {id: 2, value: "Easy to use", placeholder: "", order: 3},
          {id: 3, value: "Confusing", placeholder: "", order: 4}
        ],
        identifier: "QUESTION"
      },
      {
        id: 123456782,
        userTest_Id: 16544,
        order: 1,
        uuid: "01624c98-e7f0-3d52-9333-62724e21f7a6",
        name: "System Usability Scale Question",
        question: "<p>Please indicate how strongly you agree or disagree with the following statements.</p>",
        questionType: "SYSTEMUSABILITYSCORE",
        randomise: false,
        require: true,
        addOther: false,
        otherText: null,
        options: [
          {id: 0, value: "Strongly disagree", placeholder: "", order: 1},
          {id: 1, value: "Disagree", placeholder: "", order: 2},
          {id: 2, value: "Neither agree or disagree", placeholder: "", order: 3},
          {id: 3, value: "Agree", placeholder: "", order: 4},
          {id: 4, value: "Strongly agree", placeholder: "", order: 5}
        ],
        answers: [
          {id: 0, value: "I think that I would like to use this frequently", placeholder: "", order: 1},
          {id: 1, value: "I found this unnecessarily complex", placeholder: "", order: 2},
          {id: 2, value: "I thought this was easy to use", placeholder: "", order: 3},
          {id: 3, value: "I think that I would need the support of a technical person to be able to use this", placeholder: "", order: 4},
          {id: 4, value: "I found the various functions were well integrated", placeholder: "", order: 5},
          {id: 5, value: "I thought there was too much inconsistency", placeholder: "", order: 6},
          {id: 6, value: "I would imagine that most people would learn to use this very quickly", placeholder: "", order: 7},
          {id: 7, value: "I found this very cumbersome to use", placeholder: "", order: 8},
          {id: 8, value: "I felt very confident using this", placeholder: "", order: 9},
          {id: 9, value: "I needed to learn a lot of things before I could get going with this", placeholder: "", order: 10}
        ],
        identifier: "QUESTION"
      },
      {
        id: 123456783,
        userTest_Id: 16544,
        order: 4,
        uuid: "01624c98-e7f0-3d52-9333-62724e21f7a5",
        name: "Rating Scale Matrix",
        question: "<p>Nothing here</p>",
        questionType: "RATINGSCALEMULTI",
        randomise: false,
        require: true,
        addOther: false,
        otherText: null,
        answers: [
          {id: 0, value: "Strongly disagree", placeholder: "", order: 1},
          {id: 1, value: "Disagree", placeholder: "", order: 2},
          {id: 2, value: "Neither agree or disagree", placeholder: "", order: 3},
          {id: 3, value: "Agree", placeholder: "", order: 4},
          {id: 4, value: "Strongly agree", placeholder: "", order: 5}
        ],
        options: [
          {id: 0, value: "I think that I would like to use this frequently", placeholder: "", order: 1},
          {id: 1, value: "I found this unnecessarily complex", placeholder: "", order: 2},
          {id: 2, value: "I thought this was easy to use", placeholder: "", order: 3},
          {id: 3, value: "I think that I would need the support of a technical person to be able to use this", placeholder: "", order: 4}
        ],
        identifier: "QUESTION"
      },
      {
        id: 123456784,
        userTest_Id: 16544,
        order: 5,
        uuid: "01624c98-e7f0-3d52-9333-62724e21f7a4",
        name: "Gauge website usability",
        question: "<p>How would you rate the usability of this website?</p>",
        questionType: "RATINGSCALE1",
        randomise: false,
        require: true,
        addOther: false,
        otherText: null,
        answers: [
          {id: 0, value: "Poor", placeholder: "", order: 1},
          {id: 1, value: "Fair", placeholder: "", order: 2},
          {id: 2, value: "Good", placeholder: "", order: 3},
          {id: 3, value: "Excellent", placeholder: "", order: 4}
        ],
        identifier: "QUESTION"
      },
      {
        id: 123456785,
        userTest_Id: 16544,
        order: 6,
        uuid: "01624c98-e7f0-3d52-9333-62724e21f7a3",
        name: "Ranking the website features",
        question: "<p>Rank the following features of the website in order of how important they are to you?</p>",
        questionType: "RANKING",
        randomise: false,
        require: true,
        addOther: false,
        otherText: null,
        answers: [
          {id: 0, value: "Site map", placeholder: "", order: 1},
          {id: 1, value: "Search function steeple beeple woop rounders", placeholder: "", order: 2},
          {id: 2, value: "Video", placeholder: "", order: 3},
          {id: 3, value: "RSS feeds", placeholder: "", order: 4}
        ],
        identifier: "QUESTION"
      },
      {
        id: 123456786,
        userTest_Id: 16544,
        order: 7,
        uuid: "01624c98-e7f0-3d52-9333-62724e21f7a2",
        name: "Email address",
        question: "<p>Please enter your email address in the below field.</p>",
        questionType: "OPENENDED1",
        randomise: false,
        require: true,
        addOther: false,
        otherText: null,
        answers: null,
        identifier: "QUESTION"
      },
      {
        id: 123456787,
        userTest_Id: 16544,
        order: 8,
        uuid: "01624c98-e7f0-3d52-9333-62724e21f7a1",
        name: "Article popularity measure",
        question: "<p>What were the three articles you enjoyed most on this website?</p>",
        questionType: "OPENENDEDMULTIPLE",
        randomise: false,
        require: true,
        addOther: false,
        otherText: null,
        answers: [
          {id: 0, value: "First", placeholder: "", order: 1},
          {id: 1, value: "Second", placeholder: "", order: 2},
          {id: 2, value: "Third", placeholder: "", order: 3}
        ],
        identifier: "QUESTION"
      },
      {
        id: 123456788,
        userTest_Id: 16544,
        order: 9,
        uuid: "01624c98-e7f0-3d52-9333-62724e21f7a0",
        name: "General user sentiment",
        question: "<p>Please tell us more about why you felt this experience was lacking.</p>",
        questionType: "OPENENDEDCOMMENTS",
        randomise: false,
        require: true,
        addOther: false,
        otherText: null,
        answers: null,
        identifier: "QUESTION"
      },
      {
        id: 123456799,
        userTest_Id: 16544,
        order: 10,
        uuid: "01624c98-e7f0-3d52-9333-62724e21f7a9",
        name: "Net Promoter Score (NPS)",
        question: "<p>Based on your experience with this [WEBSITE NAME HERE] today, how likely would you be to recommend it to others?</p><h1>then this and the other one too was here merve</h1>",
        questionType: "NETPROMOTERSCORE",
        randomise: false,
        require: true,
        addOther: false,
        otherText: null,
        options: [
          {id: 0, value: "0 - Not at all likely", placeholder: "", order: 1},
          {id: 1, value: "1", placeholder: "", order: 2},
          {id: 2, value: "2", placeholder: "", order: 3},
          {id: 3, value: "3", placeholder: "", order: 4},
          {id: 4, value: "4", placeholder: "", order: 5},
          {id: 5, value: "5", placeholder: "", order: 6},
          {id: 6, value: "6", placeholder: "", order: 7},
          {id: 7, value: "7", placeholder: "", order: 8},
          {id: 8, value: "8", placeholder: "", order: 9},
          {id: 9, value: "9", placeholder: "", order: 10},
          {id: 10, value: "10 - Extremely likely", placeholder: "", order: 11}
        ],
        identifier: "QUESTION"
      }
    ]

export default questions;
