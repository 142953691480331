const educationLevel = [
      {
        id: 1,
        uuid: '12345678901',
        name: "High School"
      },
      {
        id: 2,
        uuid: '12345678902',
        name: "Tertiary (University/College)",
      },
      {
        id: 3,
        uuid: '12345678903',
        name: "Masters Level"
      },
      {
        id: 4,
        uuid: '12345678904',
        name: "Post Graduate"
      },
      {
        id: 5,
        uuid: '12345678905',
        name: "Doctorate/PhD"
      }
    ]

export default educationLevel;
