const countries = [
{name: "Afghanistan", code: "AF"},
{name: "Antarctica", code: "AQ"},
{name: "Antigua and Barbuda", code: "AG"},
{name: "Australia", code: "AU"},
{name: "Bolivia", code: "BO"},
{name: "Bosnia and Herzegovina", code: "BA"},
{name: "Canada", code: "CA"},
{name: "Denmark", code: "DK"},
{name: "Ecuador", code: "EC"},
{name: "Egypt", code: "EG"},
{name: "France", code: "FR"},
{name: "Germany", code: "DE"},
{name: "Hong Kong", code: "HK"},
{name: "India", code: "IN"},
{name: "Japan", code: "JP"},
{name: "Korea, Democratic Peoples' Republic of", code: "KP"},
{name: "Lithuania", code: "LT"},
{name: "Mexico", code: "MX"},
{name: "New Zealand", code: "NZ"},
{name: "Portugal", code: "PT"},
{name: "Russian Federation", code: "RU"},
{name: "Singapore", code: "SG"},
{name: "Switzerland", code: "CH"},
{name: "Thailand", code: "TH"},
{name: "United Kingdom", code: "GB"},
{name: "United States", code: "US"},
{name: "Zimbabwe", code: "ZW"}
]

export default countries;
