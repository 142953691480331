const criteriaType = [
      {
        id: 1,
        name: "Gender",
        type: "GENDER"
      },
      {
        id: 2,
        name: "Age",
        type: "AGE_RANGE"
      },
      {
        id: 3,
        name: "Location",
        type: "LOCATION"
      },
      {
        id: 4,
        name: "Device",
        type: "DEVICE_TYPE"
      },
      {
        id: 5,
        name: "Yearly household income",
        type: "YEARLY_HOUSEHOLD_INCOME"
      },
      {
        id: 6,
        name: "Highest level of education",
        type: "EDUCATION"
      },
      {
        id: 7,
        name: "Language",
        type: "LANGUAGE"
      }
    ]

export default criteriaType;
