export const questionColumns = [
    {id: 0, questionId: 123456782, column: "Strongly disagree", placeholder: "", order: 1},
    {id: 1, questionId: 123456782, column: "Disagree", placeholder: "", order: 2},
    {id: 2, questionId: 123456782, column: "Neither agree or disagree", placeholder: "", order: 3},
    {id: 3, questionId: 123456782, column: "Agree", placeholder: "", order: 4},
    {id: 4, questionId: 123456782, column: "Strongly agree", placeholder: "", order: 5},

    {id: 0, questionId: 123456783, column: "Strongly disagree", placeholder: "", order: 1},
    {id: 1, questionId: 123456783, column: "Disagree", placeholder: "", order: 2},
    {id: 2, questionId: 123456783, column: "Neither agree or disagree", placeholder: "", order: 3},
    {id: 3, questionId: 123456783, column: "Agree", placeholder: "", order: 4},
    {id: 4, questionId: 123456783, column: "Strongly agree", placeholder: "", order: 5},

    {id: 0, questionId: 123456799, column: "0 - Not at all likely", placeholder: "", order: 1},
    {id: 1, questionId: 123456799, column: "1", placeholder: "", order: 2},
    {id: 2, questionId: 123456799, column: "2", placeholder: "", order: 3},
    {id: 3, questionId: 123456799, column: "3", placeholder: "", order: 4},
    {id: 4, questionId: 123456799, column: "4", placeholder: "", order: 5},
    {id: 5, questionId: 123456799, column: "5", placeholder: "", order: 6},
    {id: 6, questionId: 123456799, column: "6", placeholder: "", order: 7},
    {id: 7, questionId: 123456799, column: "7", placeholder: "", order: 8},
    {id: 8, questionId: 123456799, column: "8", placeholder: "", order: 9},
    {id: 9, questionId: 123456799, column: "9", placeholder: "", order: 10},
    {id: 10, questionId: 123456799, column: "10 - Extremely likely", placeholder: "", order: 11}
  ]

export default questionColumns;
