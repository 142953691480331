import user from './data/user';
import tags from './data/tags';
import userTests from './data/usertests';
import participants from './data/participants';
import uuidv1 from 'uuid/v1';
import userTestLanguages from './data/usertestlanguages';
import theme from './data/themes';
import moderator from './data/moderators';
import noteTaker from './data/noteTakers';
import moderatedParticipant from './data/moderatedParticipants'
import questions from './data/questions';
import questionRows from './data/questionRows';
import questionColumns from './data/questionColumns';
import tasks from './data/tasks';
import taskSuccess from './data/taskSuccess';
import criteriaType from './data/criteriaType';
import educationLevel from './data/educationLevel';
import deviceCategory from './data/deviceCategory';
import deviceOS from './data/deviceOS';
import countries from './data/countries';
import regions from './data/regions';

const resolvers = {
    Mutation: {

        userTestAddTag: (_, { tagName, userTestUuid }, __) => {
            //console.log('MUTATION: userTestAddTag', tagName, userTestUuid)

            const tag = tags.find(x => x.name === tagName)
            const user_test = userTests.find(x => x.uuid === userTestUuid)
            if (user_test !== undefined) {

                // Create a new tag it needed,  just like the back end would
                if (tag === undefined) {
                    let tag = {
                        uuid: uuidv1(),
                        name: tagName,
                        projectCount: 0,
                        videoCount: 0
                    }
                    tags.push(tag)
                }

                tag.projectCount += 1
                user_test.tags.push(tag)

                return ({ 'tag': tag, 'userTest': user_test })
            }

        },

        userTestRemoveTag: (_, { tagName, userTestUuid }, __) => {
            //console.log('MUTATION: userTestRemoveTag', tagName, userTestUuid)

            const tag = tags.find(x => x.name === tagName)
            const user_test = userTests.find(x => x.uuid === userTestUuid)
            if (tag !== undefined && user_test !== undefined) {

                const index = user_test.tags.findIndex(x => x.name === tag.name)

                if (index > -1) {
                    tag.projectCount -= 1
                    user_test.tags.splice(index, 1)
                }
            }
            return ({ 'userTest': user_test })
        }
    },
    Query: {
        user: () => (user),

        tag: (_, { uuid }, __) => {
            //console.log("RESOLVER: tag")
            return (tags.find(x => x.uuid === uuid));
        },

        tags: (_, { uuid, limit }, __) => {
            //console.log("RESOLVER: tags")

            let objects = tags;

            if (uuid !== undefined) { objects = objects.filter(x => x.uuid === uuid); }
            if (limit !== undefined) { objects = objects.slice(0, limit); }
            return (objects);
        },

        userTest: (_, { id, uuid }, __) => {
            //console.log("RESOLVER: userTest")

            if (id !== undefined) {
                return (userTests.find(x => x.id === id));
            }
            if (uuid !== undefined) {
                return (userTests.find(x => x.uuid === uuid));
            }
            return ({})
        },

        userTests: (_, { uuid, launched, active }, __) => {

            //console.log('RESOLVER: userTests')

            let objects = userTests;

            if (uuid !== undefined) { objects = objects.filter(x => x.uuid === uuid); }
            if (launched !== undefined) { objects = objects.filter(x => x.launched === launched); }
            if (active !== undefined) { objects = objects.filter(x => x.active === active); }

            return (objects);
        },

        userTestLanguages: () => (userTestLanguages),

        theme: (_, { uuid }, __) => {

          let objects = theme;

          if (uuid !== undefined) { objects = objects.filter(x => x.uuid === uuid); }

          return (objects);
        },

        moderator: (_, { uuid, user_test_id }, __) => {

          if (uuid !== undefined) {
              return (moderator.find(x => x.uuid === uuid));
          }

          if (user_test_id !== undefined) {
              return (moderator.find(x => x.user_test_id === user_test_id));
          }
          return ({})
        },

        noteTaker: (_, { moderator_id }, __) => {

          let objects = noteTaker;

          if (moderator_id !== undefined) { objects = objects.filter(x =>
              x.moderator_id === moderator_id && x.deleted !== true
            );
          }

          return (objects);
        },

        moderatedParticipant: (_, { moderator_id }, __) => {

          let objects = moderatedParticipant;

          if (moderator_id !== undefined) { objects = objects.filter(x =>
              x.moderator_id === moderator_id && x.deleted !== true
            );
          }

          return (objects);
        },

        questions: (_, { uuid, userTest_Id }, __) => {

            //console.log('RESOLVER: questions')

            let objects = questions;

            if (uuid !== undefined) { objects = objects.filter(x => x.uuid === uuid); }
            if (userTest_Id !== undefined) { objects = objects.filter(x => x.userTest_Id === userTest_Id); }

            return (objects);
        },

        questionRows: (_, { uuid, questionId }, __) => {

            //console.log('RESOLVER: questionRows')

            let objects = questionRows;

            if (uuid !== undefined) { objects = objects.filter(x => x.uuid === uuid); }
            if (questionId !== undefined) { objects = objects.filter(x => x.questionId === questionId); }

            return (objects);
        },

        questionColumns: (_, { uuid, questionId }, __) => {

            //console.log('RESOLVER: questionColumns')

            let objects = questionColumns;

            if (uuid !== undefined) { objects = objects.filter(x => x.uuid === uuid); }
            if (questionId !== undefined) { objects = objects.filter(x => x.questionId === questionId); }

            return (objects);
        },

        tasks: (_, { uuid, userTest_Id }, __) => {

            //console.log('RESOLVER: tasks')

            let objects = tasks;

            if (uuid !== undefined) { objects = objects.filter(x => x.uuid === uuid); }
            if (userTest_Id !== undefined) { objects = objects.filter(x => x.userTest_Id === userTest_Id); }

            return (objects);
        },

        taskSuccess: (_, { uuid, taskId }, __) => {

            //console.log('RESOLVER: taskSuccess')

            let objects = taskSuccess;

            if (taskId !== undefined) { objects = objects.filter(x => x.taskId === taskId); }
            if (uuid !== undefined) { objects = objects.filter(x => x.uuid === uuid); }

            return (objects);
        },

        recruitmentCriteriaType: (_, { uuid }, __) => {

            //console.log('RESOLVER: criteriaType')

            let objects = criteriaType;

            if (uuid !== undefined) { objects = objects.filter(x => x.uuid === uuid); }

            return (objects);
        },

        educationLevel: (_, { uuid }, __) => {

            //console.log('RESOLVER: educationLevel')

            let objects = educationLevel;

            if (uuid !== undefined) { objects = objects.filter(x => x.uuid === uuid); }

            return (objects);
        },
        deviceCategory: (_, { uuid }, __) => {

            //console.log('RESOLVER: deviceCategory')

            let objects = deviceCategory;

            if (uuid !== undefined) { objects = objects.filter(x => x.uuid === uuid); }

            return (objects);
        },
        deviceOS: (_, { uuid, parentUuid }, __) => {

            //console.log('RESOLVER: deviceOS')

            let objects = deviceOS;

            if (uuid !== undefined) { objects = objects.filter(x => x.uuid === uuid); }
            if (parentUuid !== undefined) { objects = objects.filter(x => x.parentUuid === parentUuid); }

            return (objects);
        },

        countries: (_, { code }, __) => {

            //console.log('RESOLVER: countries')

            let objects = countries;

            if (code !== undefined) { objects = objects.filter(x => x.code === code); }

            return (objects);
        },

        regions: (_, { countryCode }, __) => {

            //console.log('RESOLVER: regions')

            let objects = regions;

            if (countryCode !== undefined) { objects = objects.filter(x => x.countryCode === countryCode); }

            return (objects);
        },

        participants: (_, { uuid, id_In, userTest_Id, userTest_Uuid, applyFiltering, limit, ordering}, __) => {

            //console.log('RESOLVER: participants')

            let objects = participants;

            if (id_In !== undefined && id_In.length > 0) {
              const id_as_str = id_In.map(x=>`${x}`)
              objects = objects.filter(x => id_as_str.includes(x.id));
            }

            if (uuid !== undefined) { objects = objects.filter(x => x.uuid === uuid); }
            if (userTest_Id !== undefined) { objects = objects.filter(x => x.userTest.id === userTest_Id); }
            if (userTest_Uuid !== undefined) { objects = objects.filter(x => x.userTest.uuid === userTest_Uuid); }

            if(limit !== undefined && objects){
                objects = objects.slice(0, limit)
            }

            return (objects);
        }


    }
};

export default resolvers;
