const deviceCategory = [
      {
        id: 1,
        uuid: '12345678901',
        name: "Desktop/Laptop"
      },
      {
        id: 2,
        uuid: '12345678902',
        name: "Phone",
      },
      {
        id: 3,
        uuid: '12345678903',
        name: "Tablet"
      }
    ]

export default deviceCategory;
