let tags = [
    {
        "uuid": "771ef145-d485-4d3b-986e-88b52197664b",
        "name": "UI",
        "projectCount": 2,
        "videoCount": 0
    },
    {
        "uuid": "0f91ff96-cf96-4dd2-8606-23d7d600c2ea",
        "name": "Old",
        "projectCount": 1,
        "videoCount": 0
    },
    {
        "uuid": "2ea98579-ac32-455a-8ec7-37c43616ca34",
        "name": "New",
        "projectCount": 0,
        "videoCount": 0
    },
    {
        "uuid": "e5ee98d3-c6d0-4de5-af22-e133572b6b47",
        "name": "Renegade Cat Fighters",
        "projectCount": 0,
        "videoCount": 0
    },
    {
        "uuid": "24492212-7940-4acb-b859-319aabe2f38d",
        "name": "Mighty Blighters",
        "projectCount": 0,
        "videoCount": 0
    },
    {
        "uuid": "6ff65510-0a11-47bb-a7fe-2626f02f00ec",
        "name": "Stonking",
        "projectCount": 0,
        "videoCount": 0
    },
    {
        "uuid": "7c604e99-3880-4675-af33-6247c7bcbed7",
        "name": "Quite food",
        "projectCount": 0,
        "videoCount": 0
    },
    {
        "uuid": "53e0703d-8a15-4870-a224-378187e8d906",
        "name": "House pins",
        "projectCount": 0,
        "videoCount": 0
    },
    {
        "uuid": "a6a2ebfb-99d0-42c7-a62c-61044364be05",
        "name": "Eck",
        "projectCount": 0,
        "videoCount": 0
    },
    {
        "uuid": "f3f9976c-d602-4139-9d7b-0a988d6730a6",
        "name": "Mit",
        "projectCount": 0,
        "videoCount": 0
    }
]

export default tags;