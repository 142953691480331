const userTestLanguages = [
      {
        "id": "1",
        "name": "English",
        "code": "en"
      },
      {
        "id": "2",
        "name": "Arabic",
        "code": "ar"
      },
      {
        "id": "3",
        "name": "Bulgarian",
        "code": "bg"
      },
      {
        "id": "6",
        "name": "Croatian",
        "code": "hr"
      },
      {
        "id": "7",
        "name": "Czech",
        "code": "cs"
      },
      {
        "id": "8",
        "name": "Danish",
        "code": "da"
      },
      {
        "id": "9",
        "name": "Dutch",
        "code": "nl"
      },
      {
        "id": "10",
        "name": "Finnish",
        "code": "fi"
      },
      {
        "id": "11",
        "name": "French",
        "code": "fr"
      },
      {
        "id": "12",
        "name": "German",
        "code": "de"
      }
    ]

export default userTestLanguages;
