const moderator = [
      {
        "uuid": "007c8f79-ab60-4d5a-a08a-1ce6be448bcd",
        "created": "2019-03-12 13:53:18.523421+00",
        "modified":"2019-03-12 13:53:18.523449+00",
        "created_by_id": 45272,
        "user_test_id": 16544
      }
    ]

export default moderator;
