const tasks = [
      {
        id: 123456791,
        userTest_Id: 16544,
        order: 12,
        uuid: "12345678987654321",
        userTest_Uuid: null,
        limit: null,
        first: null,
        last: null,
        offset: null,
        ordering: 1,
        name: "Task 17",
        scenario: "<p>Imagine that you were a first time customer to this website.</p><p>Go and find out how you become a member and the create an account.</p><p>You have 60 seconds to complete this task.</p>",
        timeLimit: 60,
        firstClick: false,
        taskType:"TIMEBOUND",
        urlStart: "http://www.spikewebdesign.com.au/",
        minimumNumberOfPages: 3,
        identifier: "TASK"
      },
      {
        id: 123456792,
        userTest_Id: 12345,
        order: 12,
        uuid: "",
        userTest_Uuid: null,
        limit: null,
        first: null,
        last: null,
        offset: null,
        ordering: 1,
        name: '',
        scenario: '',
        timeLimit: 60,
        firstClick: false,
        taskType:"STANDARD",
        urlStart: null,
        minimumNumberOfPages: 1,
        identifier: "TASK"
      }
    ]

export default tasks;
