const noteTaker = [
      {
        "uuid": "46b77c25-ad3f-4da9-98de-0de94fbbad20",
        "created": "2019-03-12 13:53:18.523421+00",
        "modified":"2019-03-12 13:53:18.523449+00",
        "email": "jordan.howe@aviva.com",
        "first_name": "Bill",
        "last_name": "Williamns",
        "moderator_id": "007c8f79-ab60-4d5a-a08a-1ce6be448bcd",
      },
      {
        "uuid": "46b77c25-ad3f-4da9-98de-0de94fbbad19",
        "created": "2019-03-12 13:53:17.523421+00",
        "modified":"2019-03-12 13:53:17.523449+00",
        "email": "kell.bell@aviva.com",
        "first_name": "Kell",
        "last_name": "Bell",
        "moderator_id": "007c8f79-ab60-4d5a-a08a-1ce6be448bcd",
      },
      {
        "uuid": "46b77c25-ad3f-4da9-98de-0de94fbbad18",
        "created": "2019-03-12 13:53:16.523421+00",
        "modified":"2019-03-12 13:53:16.523449+00",
        "email": "long.john@aviva.com",
        "first_name": "John",
        "last_name": "Long",
        "moderator_id": "007c8f79-ab60-4d5a-a08a-1ce6be448bcd",
      }
    ]

export default noteTaker;
