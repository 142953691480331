
// Created on 2019-06-06 23:56:12.261290
// ./manage.py export_graph_ql_schema

const typeDefs = `

scalar Int
scalar ID
scalar String
scalar UUID
scalar Boolean
scalar JSONString
scalar DateTime
scalar Float

enum TaskSuccessFilter {
  IS
  CONTAINS
  FINISHES
  STARTS
}

enum UserTestLanguageDir {
  LTR
  RTL
}

enum UserCountry {
  AU
  US
  GB
  AF
  AX
  AL
  DZ
  AS
  AD
  AO
  AI
  AQ
  AG
  AR
  AM
  AW
  AT
  AZ
  BS
  BH
  BD
  BB
  BY
  BE
  BZ
  BJ
  BM
  BT
  BO
  BQ
  BA
  BW
  BV
  BR
  IO
  BN
  BG
  BF
  BI
  CV
  KH
  CM
  CA
  KY
  CF
  TD
  CL
  CN
  CX
  CC
  CO
  KM
  CG
  CD
  CK
  CR
  CI
  HR
  CU
  CW
  CY
  CZ
  DK
  DJ
  DM
  DO
  EC
  EG
  SV
  GQ
  ER
  EE
  SZ
  ET
  FK
  FO
  FJ
  FI
  FR
  GF
  PF
  TF
  GA
  GM
  GE
  DE
  GH
  GI
  GR
  GL
  GD
  GP
  GU
  GT
  GG
  GN
  GW
  GY
  HT
  HM
  VA
  HN
  HK
  HU
  IS
  IN
  ID
  IR
  IQ
  IE
  IM
  IL
  IT
  JM
  JP
  JE
  JO
  KZ
  KE
  KI
  KW
  KG
  LA
  LV
  LB
  LS
  LR
  LY
  LI
  LT
  LU
  MO
  MK
  MG
  MW
  MY
  MV
  ML
  MT
  MH
  MQ
  MR
  MU
  YT
  MX
  FM
  MD
  MC
  MN
  ME
  MS
  MA
  MZ
  MM
  NA
  NR
  NP
  NL
  NC
  NZ
  NI
  NE
  NG
  NU
  NF
  KP
  MP
  NO
  OM
  PK
  PW
  PS
  PA
  PG
  PY
  PE
  PH
  PN
  PL
  PT
  PR
  QA
  RE
  RO
  RU
  RW
  BL
  SH
  KN
  LC
  MF
  PM
  VC
  WS
  SM
  ST
  SA
  SN
  RS
  SC
  SL
  SG
  SX
  SK
  SI
  SB
  SO
  ZA
  GS
  KR
  SS
  ES
  LK
  SD
  SR
  SJ
  SE
  CH
  SY
  TW
  TJ
  TZ
  TH
  TL
  TG
  TK
  TO
  TT
  TN
  TR
  TM
  TC
  TV
  UG
  UA
  AE
  UM
  UY
  UZ
  VU
  VE
  VN
  VG
  VI
  WF
  EH
  YE
  ZM
  ZW
}

enum UserSector {
  A_
  ACCOUNTING
  ADMINISTRATION_OFFICE_SUPPORT
  AUTOMOTIVE
  BANKING_AND_FINANCIAL_SERVICES
  COMMUNITY_SPORT_AND_LEISURE
  CONSTRUCTION_BUILDING_AND_ARCHITECTURE
  CUSTOMER_SERVICE_AND_CALL_CENTRE
  EDUCATION
  ENGINEERING
  EXECUTIVE
  GOVERNMENT_AND_DEFENCE
  GRADUATE
  HOSPITALITY_TRAVEL_AND_TOURISM
  HUMAN_RESOURCES_AND_RECRUITMENT
  INSURANCE
  IT_AND_TELECOMMUNICATIONS
  LEGAL
  LOGISTICS_TRANSPORT_AND_SUPPLY
  MANUFACTURING
  MARKETING
  MEDIA_ADVERTISING_ARTS_AND_ENTERTAINMENT
  MEDICAL_AND_HEALTHCARE
  MINING_OIL_AND_GAS
  PR_AND_COMMUNICATIONS
  PRIMARY_INDUSTRY
  PROPERTY_AND_REAL_ESTATE
  RETAIL
  SALES
  SCIENTIFIC
  SELF_EMPLOYMENT
  STUDENT
  TRADES_AND_SERVICES
  OTHER
}

enum UserGroup {
  AG
  AR
  CO
  CR
  ED
  FI
  GO
  GV
  HL
  LE
  MA
  ME
  OR
  RE
  SE
  TE
  TR
}

enum UserIndustry {
  ACC
  AAV
  ADI
  AME
  ANI
  AAN
  AND
  ADC
  AUT
  ADA
  BAN
  BIO
  BME
  BMA
  BSU
  CMA
  CHE
  CAN
  CEN
  CRE
  CND
  CGA
  CHA
  CNE
  CSO
  CON
  CEL
  CGO
  CSE
  COS
  DAI
  DAN
  DES
  E_L
  EMA
  EEL
  ENT
  ESE
  EER
  EOF
  FSE
  FAR
  FER
  FRT
  FIS
  FAN
  FPR
  FUN
  FUR
  GAN
  GCE
  GAD
  GRE
  GDE
  HWE
  HED
  HAN
  HOS
  HRE
  IAN
  IND
  IAU
  ISE
  ITE
  INS
  IAF
  ITR
  INT
  IBA
  IMA
  JUD
  LEN
  LPR
  LSE
  LOF
  LTR
  LIB
  LAN
  LGO
  MAC
  MCO
  MAR
  MRE
  MAN
  MOR
  MPR
  MDE
  MRA
  MHE
  MIL
  MND
  MPI
  MDI
  MUS
  NAN
  NEW
  NOR
  OAN
  OME
  OOF
  PFR
  PAN
  PND
  PAR
  PHA
  PHI
  PHO
  PLA
  POR
  PSE
  PRI
  PTR
  PDE
  PPO
  PRE
  PSA
  PUB
  RMA
  RAN
  RES
  RFA
  RIN
  RND
  RSE
  RST
  RET
  SAN
  SEM
  SHI
  SGO
  SPO
  SND
  SUP
  TEL
  TEX
  TTA
  TOB
  TAN
  TTR
  UTI
  VCA
  VET
  WAR
  WHO
  WAN
  WIR
  WND
}

enum UserLoop11Assist {
  A_
  TESTING_A_WEBSITE
  TESTING_A_WIREFRAME
  TESTING_A_PROTOTYPE
  A_B_TESTING
  BENCHMARKING
  USABILITY_TESTING
  FINDABILITY_TESTING
  ACCESSIBILITY_TESTING
  TRUE_INTENT_TESTING
  I_AM_NOT_SURE
  OTHER
}

enum NoteKind {
  N
  C
}

enum VideoRecordingStep {
  U
  I
  S
  C
  T
  D
}

enum VideoRecordingError {
  S
  M
  V
  F
  T
  P
  A
  K
  N
  E
  C
  X
}

enum VideoRecordingKind {
  M
  U
}

enum VideoRecordingCreatorMethod {
  N
  O
}

enum UserTestAudioOption {
  M
  O
  D
}

enum UserTestFaceOption {
  M
  O
  D
}

enum UserTestScreenOption {
  M
  O
  D
}

enum TaskResultState {
  A
  F
  C
  S
}
enum CriteriaTypeOptions {
  GENDER
  AGE_RANGE
  LOCATION
  DEVICE_TYPE
  YEARLY_HOUSEHOLD_INCOME
  EDUCATION
  LANGUAGE
}

enum QuestionQuestionType {
  MULTICHOICE1
  MULTICHOICEMULTI
  NETPROMOTERSCORE
  OPENENDED1
  OPENENDEDCOMMENTS
  OPENENDEDMULTIPLE
  RANKING
  RATINGSCALE1
  RATINGSCALEMULTI
  SYSTEMUSABILITYSCORE
}

type BrowserType {
  created: Int
  id: ID!
  modified: Int
  name: String!
  participantSet: [ParticipantType]
  vendor: String!
  version: String!}

type CriteriaType {
  id: ID!
  name: String!
  type: CriteriaTypeOptions}

type ModeratorType {
  uuid: UUID!
  created: DateTime!
  modified: DateTime
  created_by_id: Int
  user_test_id: Int
  deleted: Boolean!}

type ModeratedParticipantType {
    uuid: UUID!
    created: DateTime!
    modified: DateTime
    first_name: String
    participant_id: Int
    description: String
    moderator_id: UUID
    recording_session_token: String
    deleted: Boolean!}

type NoteTakerType {
  uuid: UUID!
  created: DateTime!
  modified: DateTime
  email: String
  first_name: String
  last_name: String
  moderator_id: UUID
  recording_session_token: String
  deleted: Boolean!}

type ParticipantType {
  address: AddressType
  appendedQueryString: String
  browser: BrowserType
  dateTimeEnd: Int
  dateTimeStart: Int
  deviceCategory: String
  duration: String
  hidden: Boolean!
  id: ID!
  internalNote: String
  ipAddress: String
  name: String
  note: String
  os: OSType
  questionresultSet: [QuestionResultType]
  statistics: ParpicipantStatisticsType
  taskresultSet: [TaskResultType]
  userAgent: String!
  userTest: UserTestType!
  uuid: UUID!
  videoRecording: VideoType}

type OSType {
  created: Int
  id: ID!
  modified: Int
  name: String!
  participantSet: [ParticipantType]
  version: String!}

type UserTestType {
  active: Boolean!
  anonymiseAllIps: Boolean!
  appendQueryString: Boolean
  audioOption: UserTestAudioOption!
  created: Int
  createdDate: Int
  creator: UserType
  deleted: Boolean!
  embeddingJavascript: Boolean
  enableNewPuiWhileInBeta: Boolean!
  faceOption: UserTestFaceOption!
  filterDeviceCategory: [String]!
  foundLinksOnStartUrl: Boolean!
  id: ID!
  includeIncompleteParticipants: Boolean
  introduction: String!
  invitationRate: Int
  inviteParticipants: String!
  inviteParticipantsText: String!
  isExtensionFallback: Boolean!
  language: UserTestLanguageType
  launched: Boolean!
  launchedAsThirtySecondTest: Boolean!
  launchedDate: Int
  maximumClicks: Int
  maximumParticipants: Int
  maximumTimeSpent: Int
  minimumClicks: Int
  minimumTimeSpent: Int
  moderated: Boolean!
  modified: Int
  mturkStarted: Boolean!
  owner: UserType!
  participantCount: Int
  participants: [ParticipantType]
  paused: Boolean!
  permissions: [UserType]
  previewKey: String
  private: Boolean!
  publicTitle: String!
  questions: [QuestionType]
  reportGenerated: Boolean
  reportIsCurrent: Boolean
  restrictParticipant: Boolean
  restrictParticipantIpType: String!
  results: ResultsType
  screenOption: UserTestScreenOption!
  skipPreparation: Boolean
  startPageIntroduction: String!
  startUrlDetectedLanguage: String
  statistics: JSONString!
  step: Int!
  tags: [TagType]
  tasks: [TaskType]
  thankYou: String!
  thirtySecondTest: Boolean!
  thresholdAllTasks: Boolean
  thresholdClicks: Boolean
  thresholdParticipants: Boolean
  thresholdTimeSpent: Boolean
  urlOnComplete: String
  urlStart: String
  uuid: UUID!
  workingTitle: String!}

type UserTestLanguageType {
  code: String!
  countryCodes: [String]
  dir: UserTestLanguageDir!
  id: ID!
  name: String!
  usertestSet: [UserTestType]}

type EducationLevelType {
  uuid: UUID!
  id: ID!
  name: String!}

type DeviceCategoryType {
  uuid: UUID!
  id: ID!
  name: String!
}

type DeviceOSType {
  uuid: UUID!
  id: ID!
  parentUuid: UUID!
  name: String!
}

type CountryType {
  code: String!
  name: String!
}

type RegionType {
  countryCode: String!
  name: String!
  id: ID!
}

type ThemeType {
  id: ID!
  name: String!
  logoFilename: String
  displayLogo: Boolean!
  colourMainBackground: String
  colourMainForeground: String
  colourSecondaryBackground: String
  colourSecondaryForeground: String
  colourButtonBackground: String
  colourButtonForeground: String
  ownerId: ID!
  dirty: Boolean!
  created: DateTime!
  modified: DateTime
  private: Boolean!
  uuid: UUID!
  colourMinimizedBackground: String
  colourMinimizedForeground: String}

type UserType {
  activationUrlId: String!
  country: UserCountry!
  creator: [UserTestType]
  dateActivated: Int
  dateJoined: Int
  dualLockoutEvents: [DateTime]!
  dualLoginEvents: [DateTime]!
  email: String!
  emailSentSuccessfully: Boolean!
  emailVerified: Boolean!
  emails: String
  firstName: String!
  group: UserGroup
  id: ID!
  industry: UserIndustry
  intercomId: String
  isAbleToLaunchUsertests: Boolean!
  isActive: Boolean!
  lastLogin: Int
  lastName: String!
  lastSubscription: String
  loop11Agreement: Boolean
  loop11Assist: UserLoop11Assist
  modified: Int
  nickname: String
  noteSet: [NoteType]
  notifications: JSONString!
  numberOfDualLogins: Int!
  organisation: String
  owner: [UserTestType]
  password: String!
  phoneNumber: String
  picture: String
  plan: String
  previousEmails: [String]!
  referralCode: String
  seats: [TeamType]
  sector: UserSector
  signUpDate: Int
  tagSet: [TagType]
  team: [TeamType]
  username: String!
  usertestSet: [UserTestType]
  uuid: UUID}

type TeamType {
  created: Int
  id: ID!
  modified: Int
  owner: UserType!
  seat: UserType}

type NoteType {
  clipStartOffset: Float!
  clipStopOffset: Float
  created: Int
  deleted: Boolean!
  kind: NoteKind!
  modified: Int
  participant: ParticipantType
  screenImage: String
  text: String!
  user: UserType
  uuid: UUID!}

type TagType {
  created: Int
  modified: Int
  name: String!
  owner: UserType!
  projectCount: Int
  usertestSet: [UserTestType]
  uuid: UUID!
  videoCount: Int
  videorecordingSet: [VideoType]}

type VideoType {
  archiveId: String!
  archiveIsAvailable: Boolean!
  batchIds: [String]!
  created: Int
  creatorMethod: VideoRecordingCreatorMethod!
  deleted: Boolean!
  error: VideoRecordingError
  errorMessages: [String]!
  faceVideoHeight: Int!
  faceVideoWidth: Int!
  hasAudio: Boolean
  hasFaceVideo: Boolean
  hasOverlayVideo: Boolean
  hasScreenVideo: Boolean
  hasTranscoded: Boolean!
  kind: VideoRecordingKind!
  modified: Int
  participant: ParticipantType
  screenVideoHeight: Int!
  screenVideoWidth: Int!
  sizeInBytes: Int!
  statistics: JSONString!
  step: VideoRecordingStep!
  tags: [TagType]
  tracks: JSONString
  transcodingHasErrors: Boolean!
  uuid: UUID!}

type QuestionType {
  autofill: Boolean!
  answers: JSONString
  columns: [QuestionColumnType]
  commentOption: Boolean
  commentText: String!
  created: Int
  deleted: Boolean!
  id: ID!
  mandatory: Boolean
  modified: Int
  name: String
  naOption: Boolean
  order: Int!
  parentQuestion: QuestionType
  parentTask: TaskType
  question: String!
  questionResults: [QuestionResultType]
  questionType: QuestionQuestionType!
  randomRows: Boolean
  rating: JSONString
  rows: [QuestionRowType]
  statistics: QuestionStatisticType
  userTest: UserTestType!
  uuid: UUID!
  addOther: Boolean
  otherText: String}

type TaskType {
  clickStream: JSONString
  created: Int
  deleted: Boolean!
  firstClick: Boolean!
  id: ID!
  minimumNumberOfPages: Int
  modified: Int
  name: String!
  order: Int!
  parentQuestion: QuestionType
  parentTask: TaskType
  scenario: String!
  statistics: TaskStatisticType
  taskType: String
  taskresultSet: [TaskResultType]
  timeLimit: Int
  urlStart: String!
  userTest: UserTestType!
  uuid: UUID!}

type TaskStatisticType {
  abandon: Int
  avgDuration: String
  avgDurationAbandon: String
  avgDurationComplete: String
  avgDurationFailure: String
  avgDurationSuccess: String
  avgPageViews: Float
  avgPageViewsAbandon: Float
  avgPageViewsComplete: Float
  avgPageViewsFailure: Float
  avgPageViewsSuccess: Float
  complete: Int
  failure: Int
  maxDuration: String
  maxDurationAbandon: String
  maxDurationComplete: String
  maxDurationFailure: String
  maxDurationSuccess: String
  maxPageViews: Float
  maxPageViewsAbandon: Float
  maxPageViewsComplete: Float
  maxPageViewsFailure: Float
  maxPageViewsSuccess: Float
  minDuration: String
  minDurationAbandon: String
  minDurationComplete: String
  minDurationFailure: String
  minDurationSuccess: String
  minPageViews: Float
  minPageViewsAbandon: Float
  minPageViewsComplete: Float
  minPageViewsFailure: Float
  minPageViewsSuccess: Float
  success: Int
  totalPageViews: Int}

type TaskResultType {
  clickStream: JSONString
  completed: Boolean!
  dateTimeEnd: DateTime
  dateTimeStart: DateTime!
  durationInSeconds: Int
  id: ID!
  ipAddress: String
  participant: ParticipantType!
  state: TaskResultState
  statistics: JSONString!
  task: TaskType!
  userAgent: String!
  uuid: UUID}

type TaskSuccessType {
  id: ID!
  value: TaskSuccessFilter
  urlSuccess: String
  taskId: Int
  created: DateTime
  modified: DateTime
  uuid: UUID}

type QuestionStatisticType {
  netpromoterscore: NpsType
  participantAnwsered: Int
  participantCount: Int
  participantStarted: Int
  systemusabilityscore: SusType}

type SusType {
  learnability: Float
  score: Float
  usability: Float}

type NpsType {
  detractors: Int
  passives: Int
  promoters: Int
  score: Float}

type QuestionRowType {
  count: Int
  created: Int
  id: ID!
  questionId: Int
  modified: Int
  order: Int!
  questionresponseSet: [QuestionResponseType]
  row: String!
  uuid: UUID!}

type QuestionResponseType {
  answer: String
  created: Int
  id: ID!
  modified: Int
  questionColumn: QuestionColumnType
  questionResult: QuestionResultType!
  questionRow: QuestionRowType
  uuid: UUID!}

type QuestionColumnType {
  column: String!
  count: Int
  created: Int
  id: ID!
  questionId: Int
  modified: Int
  order: Int!
  questionresponseSet: [QuestionResponseType]
  uuid: UUID!}

type QuestionResultType {
  autofilled: Boolean!
  created: Int
  dateTime: Int
  id: ID!
  ipAddress: String
  modified: Int
  participant: ParticipantType!
  question: QuestionType!
  questionResponses: [QuestionResponseType]
  userAgent: String!
  uuid: UUID!}

type ResultsType {
  abandon: Int
  addresses: JSONString
  averageParticipantDuration: String
  averageTaskDuration: String
  browsers: JSONString
  complete: Int
  devices: JSONString
  failure: Int
  lostness: String
  nps: NpsType
  oses: JSONString
  participantCompleteCount: Int
  participantCount: Int
  participantIncompleteCount: Int
  participants: String
  success: Int
  sus: SusType}

type ParpicipantStatisticsType {
  abandon: Int
  complete: Int
  failure: Int
  success: Int}

type AddressType {
  city: String
  country: String
  created: Int
  id: ID!
  modified: Int
  region: String
  timeZone: String}

type UserTestAddTagMutation {
  tag: TagType
  userTest: UserTestType}

type UserTestRemoveTagMutation {
  userTest: UserTestType}

type TaskSuccessMutation {
  task: TaskType}

type Subscription {
  onQuestionUpdated: QuestionSubscription
  onTaskUpdated: TaskSubscription}

type TaskSubscription {
  task: TaskType}

type QuestionSubscription {
  question: QuestionType}

type Query {
  browser(
    id: Int
  ): BrowserType

  os(
    id: Int
  ): OSType

  note(
    uuid: String
  ): NoteType

  notes(
    limit: Int
    first: Int
    userTest_Id: Int
    userTest_Uuid: String
    last: Int
    offset: Int
    uuid: String
    ordering: String
  ): [NoteType]

  tag(
    uuid: String
  ): TagType

  tags(
    uuid: String
    limit: Int
    first: Int
    last: Int
    offset: Int
    ordering: String
  ): [TagType]

  video(
    id: Int
    uuid: String
  ): VideoType

  videos(
    uuid: String
    userTest_Uuid: String
    limit: Int
    first: Int
    last: Int
    offset: Int
    ordering: String
  ): [VideoType]

  participant(
    id: Int
    uuid: String
  ): ParticipantType

  participants(
    limit: Int
    first: Int
    id_In: [Int]
    userTest_Id: Int
    userTest_Uuid: String
    applyFiltering: Boolean
    last: Int
    offset: Int
    uuid: String
    ordering: String
  ): [ParticipantType]

  address(
    id: Int
  ): AddressType

  addresses(
    limit: Int
    first: Int
    last: Int
    offset: Int
    ordering: String
  ): [AddressType]

  userTest(
    id: Int
    uuid: String
  ): UserTestType

  userTests(
    launched: Boolean
    active: Boolean
    uuid: String
    limit: Int
    first: Int
    last: Int
    offset: Int
    ordering: String
  ): [UserTestType]

  task(
    id: Int
    uuid: String
    clickStreamDepth: Int
    clickStreamUrlDepth: Int
  ): TaskType

  tasks(
    id: Int
    uuid: String
    userTest_Id: Int
    userTest_Uuid: String
    limit: Int
    first: Int
    last: Int
    offset: Int
    ordering: String
  ): [TaskType]

  taskSuccess(
    id: Int
    value: String
    urlSuccess: String
    taskId: Int
    created: DateTime
    modified: DateTime
    uuid: String
  ): [TaskSuccessType]

  question(
    id: Int
    uuid: String
  ): QuestionType

  questions(
    uuid: String
    userTest_Id: Int
    userTest_Uuid: String
    limit: Int
    first: Int
    last: Int
    offset: Int
    ordering: String
    addOther: Boolean
    otherText: String
  ): [QuestionType]

  questionRows(
    uuid: String
    questionId: Int
    row: String
    uuid: String
    order: Int
    placeholder: String
  ): [QuestionRowType]

  questionColumns(
    uuid: String
    questionId: Int
    column: String
    uuid: String
    order: Int
    placeholder: String
  ): [QuestionColumnType]

  questionResults(
    uuid: String
    question_Id: Int
    question_Uuid: String
    question_UserTest_Uuid: String!
    limit: Int
    first: Int
    last: Int
    offset: Int
    ordering: String
  ): [QuestionResultType]

  userTestLanguage(
    id: Int
  ): UserTestLanguageType

  recruitmentCriteriaType(
    id: Int
    name: String
    type: String
  ):[CriteriaType]

  theme(
    id: Int
    name: String
    uuid: String
    logoFilename: String
    colourMainBackground: String
    colourMainForeground: String
    colourSecondaryBackground: String
    colourSecondaryForeground: String
    colourButtonBackground: String
    colourButtonForeground: String
    colourMinimizedBackground: String
    colourMinimizedForeground: String
  ): [ThemeType]

  moderator(
    uuid: UUID
    created: DateTime
    modified: DateTime
    created_by_id: Int
    user_test_id: Int
  ): ModeratorType

  moderatedParticipant(
    uuid: UUID
    created: DateTime
    modified: DateTime
    first_name: String
    participant_id: Int
    description: String
    moderator_id: UUID
    recording_session_token: String
    deleted: Boolean
  ):[ModeratedParticipantType]

  noteTaker(
    uuid: UUID
    created: DateTime
    modified: DateTime
    email: String
    first_name: String
    last_name: String
    moderator_id: UUID
    deleted: Boolean
  ): [NoteTakerType]

  userTestLanguages(
    limit: Int
    first: Int
    last: Int
    offset: Int
    ordering: String
  ): [UserTestLanguageType]

  educationLevel(
    uuid: UUID
    id: Int
    name: String
  ): [EducationLevelType]

  deviceCategory(
    uuid: UUID
    id: Int
    name: String
  ): [DeviceCategoryType]

  deviceOS(
    uuid: UUID
    id: Int
    parentUuid: UUID
    name: String
  ): [DeviceOSType]

  countries(
    code: String
    name: String
  ): [CountryType]

  regions(
    countryCode: String
    name: String
    id: Int
  ): [RegionType]

  user(
    id: Int
    uuid: String
    email: String
  ): UserType

  users(
    limit: Int
    first: Int
    last: Int
    offset: Int
    email: String
    uuid: String
    ordering: String
  ): [UserType]

  team(
    id: Int
    uuid: String
  ): TeamType

}

type Mutation {
  userTestAddTag(
    tagName: String!
    userTestUuid: String!
  ): UserTestAddTagMutation

  userTestRemoveTag(
    tagName: String!
    userTestUuid: String!
  ): UserTestRemoveTagMutation

  changeTaskSuccess(
    pageUrlsToAdd: [String]
    pageUrlsToDel: [String]
    taskUuid: String!
  ): TaskSuccessMutation

}

`

export default typeDefs;
